"use client";

import { useEffect, useMemo, useCallback } from "react";
import { ReviewList } from "./ReviewList";
import {
  fetchProductReviews,
  setQuickView,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { API_ROUTES, getLastPathSegment, IReviewData, STATUSES } from "@/utils";
import { usePathname, useRouter } from "next/navigation";
import { GlobalEmpty } from "@/components/GlobalEmpty";
import { GlobalError } from "@/components/GlobalError";

const ProductReviews = ({
  reviews,
  product_id,
  modalData = { state: false, data: null },
}: {
  reviews?: IReviewData;
  product_id?: number;
  modalData?: { state: boolean; data: any };
}) => {
  const dispatch = useAppDispatch();
  const { productReviews, filter, state } = useAppSelector(
    (state) => state.common
  );
  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );
  const addReviewToProduct = product_id || modalData.data?.product_id;
  const paths = usePathname();
  const router = useRouter();

  const reviewsToRender = useMemo(() => {
    if (reviews && reviews.review_list?.length > 0) {
      return {
        ...reviews,
        review_list: reviews.review_list.slice(0, 3),
      };
    } else if (productReviews && productReviews.review_list?.length > 0) {
      return productReviews;
    } else {
      return {
        average_rating: 0,
        total_pages: 0,
        review_list: [],
        review_statistics: [],
      };
    }
  }, [reviews, productReviews]);

  const handleWriteReview = useCallback(() => {
    if (logged_in_user || access_token) {
      dispatch(
        setQuickView({
          state: true,
          type: "product-review",
          data: { product_id },
        })
      );
    } else {
      router.push(API_ROUTES.LOGIN);
    }
  }, [dispatch, product_id]);

  useEffect(() => {
    if (modalData.state) {
      dispatch(
        fetchProductReviews({
          filters: filter,
          url: `/api/reviews/?query=${getLastPathSegment(paths)}`,
        })
      );
    }
  }, [modalData.state, filter, paths, dispatch, logged_in_user, access_token]);

  return (
    <section className="section-padding-12">
      <div className="container">
        <div className="row pb-0">
          {reviewsToRender?.review_list?.length > 0 && (
            <div className="col-12">
              <h2
                className="section-title style-1 mb-0 pb-10"
                id="listing-container"
              >
                Customer Reviews
              </h2>
            </div>
          )}
          <div className="col-12">
            {state.type === "fetch-reviews" &&
            state.status === STATUSES.ERROR ? (
              <GlobalError
                title="Error Loading Customer Reviews"
                message="Oops! Something Went Wrong It looks like there was an issue loading the customer reviews for this product. Please refresh the page or check back later. We appreciate your patience!"
              />
            ) : reviewsToRender.review_list.length === 0 &&
              filter.rating === "all" ? (
              <GlobalEmpty
                padding={60}
                title="No Customer Reviews Yet"
                message="It looks like there are no reviews for this product yet. Be the first to share your thoughts and help others discover how great it is!"
                btnText="Write a Review"
                action={handleWriteReview}
              />
            ) : (
              <ReviewList
                reviews={reviewsToRender}
                product_id={addReviewToProduct}
                isModal={!reviews}
                showSeeMore={
                  (reviews?.review_list && reviews?.review_list?.length > 3) ||
                  false
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export { ProductReviews };
