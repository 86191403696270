"use client";

import { Button, CustomInput } from "@/components/core-components";
import {
  fetchProfileDetails,
  setQuickView,
  updateProfileDetails,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  CountryCode,
  FORM_CONSTANTS,
  ProfileSchema,
  seperateFirstLastName,
  STATUSES,
} from "@/utils";
import { Form, Formik } from "formik";
import Link from "next/link";
import React, { useEffect } from "react";

const ProfileShimmer = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
    </div>
  );
};

const ProfileForm = ({
  profileData,
}: {
  profileData: {
    id: number;
    name: string;
    email: string;
    contact_number: number;
    created_at: string;
    country: CountryCode;
  };
}) => {
  const dispatch = useAppDispatch();

  const { profile_details } = useAppSelector((state) => state.auth);

  const { state } = useAppSelector((state) => state.common);

  const { first_name, last_name } = seperateFirstLastName(
    profile_details?.name || profileData?.name || ""
  );

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: first_name || "",
        last_name: last_name || "",
        contact_number:
          profile_details?.contact_number.toString() ||
          profileData?.contact_number.toString() ||
          "",
        email: profile_details?.email || profileData?.email || "",
        country: [
          {
            value:
              profile_details?.country.id || profileData?.country.id || 101,
            label: `${
              profile_details?.country.iso ||
              profile_details?.country.iso3 ||
              profileData?.country.iso ||
              profileData?.country.iso3 ||
              "IN"
            } +${
              profile_details?.country.phonecode ||
              profileData?.country.phonecode ||
              91
            }`,
            phonecode:
              profile_details?.country.phonecode ||
              profileData?.country.phonecode ||
              91,
            country:
              profile_details?.country.country ||
              profileData?.country.country ||
              "India",
          },
        ],
      }}
      onSubmit={(values, actions) => {
        dispatch(updateProfileDetails({ data: values }));
      }}
      validationSchema={ProfileSchema}
    >
      {(profileProps) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter First Name"
                formikProps={profileProps}
                label={"First Name"}
                name={"first_name"}
                isRequired={true}
                type={FORM_CONSTANTS.TEXT}
                titleCase={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Last Name"
                formikProps={profileProps}
                label={"Last Name"}
                name={"last_name"}
                isRequired={true}
                type={FORM_CONSTANTS.TEXT}
                titleCase={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Email"
                formikProps={profileProps}
                label={"Email"}
                disabled={true}
                labelAddOn={
                  <Link
                    href="#"
                    onClick={() => {
                      dispatch(
                        setQuickView({
                          state: true,
                          type: "change-details",
                          data: {
                            type: "email",
                            value: profileProps?.values?.email,
                          },
                        })
                      );
                    }}
                  >
                    Change
                  </Link>
                }
                name={"email"}
                isRequired={true}
                type={FORM_CONSTANTS.EMAIL}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Mobile Number"
                formikProps={profileProps}
                countryCode
                disabled={true}
                labelAddOn={
                  <Link
                    href="#"
                    onClick={() => {
                      dispatch(
                        setQuickView({
                          state: true,
                          type: "change-details",
                          data: {
                            type: "contact_number",
                            value: profileProps?.values?.contact_number,
                          },
                        })
                      );
                    }}
                  >
                    Change
                  </Link>
                }
                label={"Mobile Number"}
                name={"contact_number"}
                isRequired={true}
                type={FORM_CONSTANTS.NUMBER}
                titleCase={true}
              />
            </div>
            <div className="col-md-12">
              <Button
                type="submit"
                className="btn btn-sm btn-fill-out submit font-weight-bold"
                text={"Save Changes"}
                loading={
                  state.status === STATUSES.LOADING &&
                  state.type === "update-profile"
                }
              />
            </div>
          </div>
          {/* {state.status === STATUSES.LOADING &&
          state.type === "fetch-profile" ? (
            <div className="row justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (

          )} */}
        </Form>
      )}
    </Formik>
  );
};

export { ProfileForm, ProfileShimmer };
