"use client";

import {
  CategoryHorizontalListing,
  DealsList,
  ListingWithFilters,
  ProductHorizontalListing,
} from "../Listing";
import { useCallback, useEffect } from "react";
import { resetFilters, setFilteredProducts, useAppDispatch } from "@/store";

const ListingWidet = ({
  config,
  widgetIndex,
  type,
  noOfWidget,
}: {
  config: {
    type: string;
    title: string;
    content: any;
    filters: any;
    extraFilters: any;
    largeCard: boolean;
    fetchUrl: string;
    totalPages?: number;
    count?: number;
    taste?: any[];
  };
  noOfWidget?: number;
  widgetIndex: number;
  type: "category" | "sub_category" | "product" | "static";
}) => {
  const dispatch = useAppDispatch();

  const renderListing = useCallback(() => {
    const commonProps = {
      widgetIndex,
      data: config.content,
      title: config?.title || "",
    };

    const autoplayConfig =
      config.content?.length > 5
        ? {
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }
        : undefined;

    switch (config.type) {
      case "product-grid":
        return (
          <ListingWithFilters
            {...commonProps}
            taste={config.taste || []}
            total_pages={config?.totalPages}
            count={config?.count}
            type={type}
            hasDesc={type === "category"}
            filters={config.filters || []}
            extraFilters={config.extraFilters}
            largeCard={config.largeCard}
            fetchUrl={config.fetchUrl}
            last={noOfWidget === widgetIndex + 1}
          />
        );

      case "product-carousel":
        return (
          <ProductHorizontalListing
            {...commonProps}
            lengthToRender={2}
            hasDesc={false}
            slidesPerView={6}
            spaceBetween={30}
            prevBtn={"custom_prev_2"}
            nextBtn={"custom_next_2"}
            parentClassName="section-padding-12"
            btnClassName="slider-arrow-2 carausel-6-columns-arrow"
            sliderClassName="col-12"
            sliderId="row related-products position-relative"
          />
        );

      case "product-deals":
        return <DealsList {...commonProps} />;

      // case "category-carousel":
      // case "sub-category-carousel":
      //   return (

      //   );

      default:
        return (
          <CategoryHorizontalListing
            {...commonProps}
            lengthToRender={2}
            type={config.type}
            autoplay={autoplayConfig}
            parentClassName="popular-categories section-padding-12 subcategory-carousel mt-0"
            slideClassName="card-1"
            sliderClassName="carausel-8-columns-cover position-relative"
            sliderId="carausel-5-columns"
            slidesPerView={5}
            spaceBetween={12}
            prevBtn={"custom_prev_ct1"}
            nextBtn={"custom_next_ct1"}
            btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
            hasDesc={true}
            last={noOfWidget === widgetIndex + 1}
          />
        );
    }
  }, [config, type, widgetIndex, noOfWidget]);

  useEffect(() => {
    return () => {
      dispatch(setFilteredProducts(null));
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return renderListing();
};

export { ListingWidet };
