"use client";

import { ProductItem } from "./ProductItem";
import { HorizontalListing } from "../HorizontalListing";
import { SwiperSlide } from "swiper/react";
import {
  calculatePrice,
  capitalizeFirstLetter,
  GeneralSliderProps,
  IProduct,
  STATUSES,
  to2Decimal,
  trackViewItemList,
} from "@/utils";
import React, { Fragment, useEffect, useMemo } from "react";
import { usePathname, useRouter } from "next/navigation";
import { GlobalEmpty } from "@/components/GlobalEmpty";
import { useAppSelector } from "@/store";

const ProductShimmerItem = () => {
  return (
    <div className={`product-cart-wrap mb-18 `}>
      <div className="product-img-action-wrap">
        <div className="product-img product-img-zoom mx-1">
          <span
            className="skeleton skeleton-text normal-shimmer default-img"
            style={{
              display: "block",
              width: "200px",
              height: "200px",
            }}
          ></span>
        </div>
      </div>

      <div className={`product-content-wrap `}>
        <h2>
          <span className="skeleton skeleton-text normal-shimmer d-block w-100"></span>
        </h2>

        <div className="d-flex flex-row justify-content-between align-items-center gap-2 my-2">
          <div className="col-2">
            <h2>
              <span className="skeleton skeleton-text normal-shimmer d-block w-100"></span>
            </h2>
          </div>
          <div className="offset-4 col-5">
            <h2>
              <span className="skeleton skeleton-text normal-shimmer d-block w-100"></span>
            </h2>
          </div>
        </div>

        <h2>
          <span className="skeleton skeleton-text normal-shimmer d-block w-100"></span>
        </h2>

        <div className="product-card-bottom mt-5 flex-column">
          <div className="add-cart">
            <span className="skeleton skeleton-text gradient-shimmer d-block w-100"></span>
            <span className="skeleton skeleton-text gradient-shimmer d-block w-100"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductListing = ({
  type = "",
  title,
  products,
  deleteBtn,
  hasDesc = false,
  largeCard = false,
  widgetIndex,
}: {
  type: string;
  title: string;
  products: IProduct[];
  deleteBtn?: (value: any) => void;
  largeCard?: boolean;
  hasDesc?: boolean;
  widgetIndex: number;
}) => {
  const { state: commonState } = useAppSelector((state) => state.common);

  const router = useRouter();

  const paths = usePathname();

  const currentPage = paths.split("/").filter((path) => path);

  const noProductsFoundMessage = (type: string) => {
    switch (type) {
      case "category":
      case "sub_category":
        return `We couldn't find any products in the '${capitalizeFirstLetter(
          currentPage[1] || currentPage[0] || ""
        )}' category at the moment.<br/>Please explore our other categories or check back later.`;

      default:
        return "No Products Found";
    }
  };

  const noProductsFoundTitle = (type: string) => {
    switch (type) {
      case "category":
      case "sub_category":
        return `No Products Found in Category`;

      default:
        return "No Products Found";
    }
  };

  function trackGTagEvent() {
    const eventData = {
      item_list_id: type,
      item_list_name: (currentPage[1] || currentPage[0])
        .split("-")
        .map((item: any) => capitalizeFirstLetter(item))
        .join(" "),
      items: products.map((item, index) => {
        const currentPrice =
          item.selling_uom === "pcs"
            ? item.price
            : calculatePrice(1000, item.price, item?.weights?.[0]?.value);
        return {
          item_id: item.product_code,
          item_list_id: type,
          item_name: item.title,
          item_brand: "Avarya",
          item_category: item.category,
          item_category2: item.sub_category,
          quantity: 1,
          price: to2Decimal(currentPrice),
          ...(index !== undefined && { index }),
        };
      }),
    };

    trackViewItemList(eventData);

    return eventData;
  }

  useEffect(() => {
    if (products?.length > 0) {
      if (currentPage.length > 0) {
        trackGTagEvent();
      }
    }
  }, [products?.length]);

  return (
    <React.Fragment>
      {commonState.status === STATUSES.LOADING &&
      commonState.type === `fetch-products-${widgetIndex}` ? (
        Array.from({ length: 12 }).map((_, index) => (
          <div
            className={`col-md-4 col-sm-6 col-6 col-lg-${
              deleteBtn ? "1-5" : largeCard ? "3" : "2"
            }`}
            key={index}
          >
            <ProductShimmerItem />
          </div>
        ))
      ) : products?.length > 0 ? (
        products.map((product, i) => (
          <div
            className={`col-md-4 col-sm-6 col-6 col-lg-${
              deleteBtn ? "1-5" : largeCard ? "3" : "2"
            }`}
            key={i}
          >
            <ProductItem
              type={type}
              widgetIndex={widgetIndex}
              index={i}
              product={product}
              hasDesc={hasDesc}
              deleteBtn={deleteBtn}
              largeCard={largeCard}
            />
          </div>
        ))
      ) : (
        <GlobalEmpty
          title={noProductsFoundTitle(type)}
          message={noProductsFoundMessage(type)}
          btnText="Explore Other Sub-Categories"
          action={() => {
            router.push(`/${currentPage[0]}`);
          }}
        />
      )}
    </React.Fragment>
  );
};

const ProductHorizontalListing = ({
  data: initialData,
  title,
  prevBtn,
  nextBtn,
  parentClassName,
  btnClassName,
  sliderClassName,
  sliderId,
  slidesPerView = 6,
  spaceBetween,
  autoplay,
  hasDesc = false,
  widgetIndex,
  type,
  lengthToRender = 0,
  centered = false,
  onNavigationNext,
}: GeneralSliderProps) => {
  const renderProducts = useMemo(() => {
    return initialData.map((item: any, index: number) => (
      <Fragment key={index}>
        <SwiperSlide className={`product-${index + 1}`} virtualIndex={index}>
          <ProductItem
            widgetIndex={widgetIndex || 0}
            product={item}
            hasDesc={hasDesc}
            index={index}
            type={type}
          />
        </SwiperSlide>

        {!widgetIndex &&
        initialData.length % 6 === 0 &&
        initialData.length - 1 === index ? (
          <SwiperSlide
            className={`product-${index + 1}`}
            virtualIndex={index + 1}
          >
            <div className="h-100 d-flex flex-row justify-content-center align-items-center">
              <span
                className="text-primary spinner-border align-self-center"
                aria-hidden="true"
              />
            </div>
          </SwiperSlide>
        ) : null}
      </Fragment>
    ));
  }, [initialData.length, initialData.length % 6 === 0]);

  return (
    <HorizontalListing
      type={type}
      autoplay={autoplay}
      title={title}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      prevBtn={prevBtn}
      nextBtn={nextBtn}
      parentClassName={parentClassName}
      btnClassName={btnClassName}
      sliderClassName={sliderClassName}
      sliderId={sliderId}
      onNavigationNext={onNavigationNext}
      centered={centered}
      dataLength={initialData.length}
      toRender={initialData.length >= lengthToRender}
    >
      {initialData.length >= lengthToRender ? renderProducts : null}
    </HorizontalListing>
  );
};

export { ProductListing, ProductHorizontalListing };
