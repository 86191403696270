"use client";

import React, { useEffect, useRef, useState } from "react";
import { Button, CustomInput } from "./core-components";
import Link from "next/link";
import { Form, Formik } from "formik";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  isNumber,
  LOGIN_CONSTANTS,
  LoginSchema,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
  trackLogin,
} from "@/utils";
import {
  fetchCountryCode,
  loginUser,
  useAppDispatch,
  useAppSelector,
  verifyOtp,
} from "@/store";
import { useRouter } from "next/navigation";
import parse from "html-react-parser";

const Login = () => {
  const dispatch = useAppDispatch();

  const { auth_state, verification_pending, customer_details } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const [otpSent, setOtpSent] = useState(
    Object.keys(customer_details || {})?.length > 0 ? 1 : 0
  );

  const [otpTimer, SetOtpTimer] = useState<{
    seconds: number;
    minutes: number;
  }>({
    minutes: Object.keys(customer_details || {})?.length > 0 ? 2 : 0,
    seconds: 0,
  });

  const router = useRouter();

  const otpRef = useRef<HTMLInputElement>(null);

  const requestOTP = (values: {
    email_or_phone: any;
    email: string;
    otp: string;
    country: any[];
  }) => {
    if (values.email_or_phone) {
      let dataToSend = {
        email: !isNumber(values.email_or_phone)
          ? values.email_or_phone
          : undefined,
        contact_number: isNumber(values.email_or_phone)
          ? Number(values.email_or_phone)
          : undefined,
        country: isNumber(values.email_or_phone)
          ? Number(values.country?.[0]?.value)
          : undefined,
      };

      dispatch(
        loginUser({
          data: dataToSend,
          router,
          onSuccess: () => {
            setOtpSent((prev) => prev + 1);

            SetOtpTimer({
              minutes: 2,
              seconds: 0,
            });
          },
        })
      );

      otpRef.current?.focus();
    } else {
      Toast({
        message: "Please enter email or contact number",
        type: TOAST_CONSTANTS.WARNING,
      });
    }
  };

  const verifyOTP = (values: {
    email_or_phone: any;
    email: string;
    otp: string;
    country: any[];
  }) => {
    let dataToSend = {
      [isNumber(values.email_or_phone)
        ? LOGIN_CONSTANTS.CONTACT_NUMBER.otp
        : LOGIN_CONSTANTS.EMAIL.otp]: Number(values.otp),
      country: isNumber(values.email_or_phone)
        ? Number(values.country?.[0]?.value)
        : undefined,
      [isNumber(values.email_or_phone)
        ? LOGIN_CONSTANTS.CONTACT_NUMBER.name
        : LOGIN_CONSTANTS.EMAIL.name]: values.email_or_phone,
    };
    if (verification_pending && values?.email) {
      dataToSend["email"] = values?.email;
    }

    dispatch(
      verifyOtp({
        data: dataToSend,
        router,
        onSuccess: () => {
          trackLogin({
            method: isNumber(values.email_or_phone)
              ? "contact_number"
              : "email",
          });
        },
      })
    );
  };

  const handleSubmit = (values: {
    email_or_phone: any;
    email: string;
    otp: string;
    country: any[];
  }) => {
    if (values.otp) {
      verifyOTP(values);
    } else {
      requestOTP(values);
    }
  };

  useEffect(() => {
    dispatch(fetchCountryCode());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer.seconds > 0) {
        SetOtpTimer({
          ...otpTimer,
          seconds: otpTimer.seconds - 1,
        });
      }

      if (otpTimer.seconds === 0) {
        if (otpTimer.minutes === 0) {
          clearInterval(interval);
        } else {
          SetOtpTimer({
            seconds: 59,
            minutes: otpTimer.minutes - 1,
          });
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className="page-content login-page-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8 col-md-12 col-sm-8 text-center">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all">
                <div className="heading_s1 text-center mb-20">
                  <h1 className="mb-5 font-32 text-start">
                    {`Sign in ${otpSent > 0 ? "" : "or create account"}`}
                  </h1>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    email_or_phone:
                      Object.keys(customer_details || {})?.length > 0
                        ? customer_details.contact_number
                        : "",
                    email: "",
                    otp: "",
                    country: [
                      {
                        country: "India",
                        phonecode: 91,
                        value: 101,
                        label: "IN +91",
                      },
                    ],
                  }}
                  validationSchema={LoginSchema({
                    verification_pending: verification_pending,
                    otpSent: otpSent > 0,
                  })}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="row mb-15">
                        {otpSent > 0 ? (
                          <div className="d-flex flex-row align-items-center gap-1 mb-10">
                            {isNumber(props.values.email_or_phone)
                              ? `+${props.values.country?.[0]?.phonecode} ${props.values.email_or_phone}`
                              : props.values.email_or_phone}
                            {!(
                              // verification_pending ||
                              (Object.keys(customer_details || {})?.length > 0)
                            ) && (
                              <Link
                                href="#"
                                onClick={() => {
                                  props.setFieldValue(
                                    LOGIN_CONSTANTS.OTP.name,
                                    ""
                                  );
                                  setOtpSent(0);
                                }}
                              >
                                Change
                              </Link>
                            )}
                          </div>
                        ) : (
                          <CustomInput
                            required
                            placeholder={
                              LOGIN_CONSTANTS.EMAIL_OR_CONTACT.placeholder
                            }
                            countryCode={true}
                            formikProps={props}
                            name={LOGIN_CONSTANTS.EMAIL_OR_CONTACT.name}
                            type={FORM_CONSTANTS.TEXT}
                          />
                        )}
                        {otpSent > 0 && verification_pending && (
                          <CustomInput
                            inputRef={otpRef}
                            required
                            placeholder={LOGIN_CONSTANTS.EMAIL.placeholder}
                            formikProps={props}
                            name={LOGIN_CONSTANTS.EMAIL.name}
                            type={FORM_CONSTANTS.EMAIL}
                          />
                        )}
                        {otpSent > 0 && (
                          <CustomInput
                            inputRef={otpRef}
                            required
                            placeholder={LOGIN_CONSTANTS.OTP.placeholder}
                            formikProps={props}
                            name={LOGIN_CONSTANTS.OTP.name}
                            type={FORM_CONSTANTS.NUMBER}
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                props.submitForm();
                                // verifyOTP(props.values);
                              }
                            }}
                          />
                        )}
                        {otpSent > 0 && (
                          <div className="text-xs text-start pb-10">
                            {`OTP sent on ${
                              isNumber(props.values?.email_or_phone)
                                ? "WhatsApp"
                                : "Email"
                            }`}
                          </div>
                        )}
                      </div>
                      <div className="text-xs text-start pb-10">
                        {parse(
                          `By continuing, you agree to Avarya's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                        )}
                      </div>
                      <div className="row">
                        {otpSent > 0 && (
                          <div className="col-12">
                            <Button
                              loading={
                                state.status == STATUSES.LOADING &&
                                state.type === "verify-otp"
                              }
                              text={`Verify`}
                              type={BUTTON_CONSTANTS.BUTTON}
                              className="btn btn-sm w-100 mb-10"
                              onClick={() => props.submitForm()}
                            />
                          </div>
                        )}
                        <div className="col-12">
                          {otpSent === 0 && (
                            <Button
                              text={LOGIN_CONSTANTS.REQUEST_OTP}
                              type={BUTTON_CONSTANTS.BUTTON}
                              loading={auth_state === STATUSES.LOADING}
                              disabled={
                                otpSent > 0 &&
                                (otpTimer.minutes > 0 || otpTimer.seconds > 0)
                              }
                              className="btn btn-sm w-100"
                              onClick={() => props.submitForm()}
                            />
                          )}
                          {otpSent > 0 ? (
                            <p>
                              Not received your code?{" "}
                              <Link
                                href={"#"}
                                onClick={() => {
                                  if (
                                    !(
                                      otpTimer.seconds > 0 ||
                                      otpTimer.minutes > 0
                                    )
                                  ) {
                                    props.setFieldValue(
                                      LOGIN_CONSTANTS.OTP.name,
                                      ""
                                    );
                                    requestOTP(props.values);
                                  }
                                }}
                              >
                                {otpTimer.seconds > 0 || otpTimer.minutes > 0
                                  ? `${
                                      otpTimer.minutes < 10
                                        ? `0${otpTimer.minutes}`
                                        : otpTimer.minutes
                                    }:${
                                      otpTimer.seconds < 10
                                        ? `0${otpTimer.seconds}`
                                        : otpTimer.seconds
                                    }`
                                  : LOGIN_CONSTANTS.RESEND_OTP}
                              </Link>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
