import React from "react";
import { BannerItemProps } from "@/utils";
import Image from "next/image";

const BannerItem = ({
  banner,
  length,
  index,
  widgetIndex,
  direction,
}: {
  banner: BannerItemProps;
  length: number;
  index: number;
  widgetIndex: number;
  direction: string;
}) => {
  return (
    <div
      className={`${
        direction == "vertical"
          ? "col-12"
          : `col-lg-${length >= 2 ? 4 : 6} ${
              index >= 2 ? "d-md-none d-lg-inline" : "col-md-6"
            }`
      }
        `}
    >
      <div
        className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp"
        data-wow-delay=".4s"
      >
        <Image
          width={banner.width || 500}
          height={banner.height || 400}
          className="w-100 h-100"
          src={banner?.img || "/assets/images/banners/banner-1.jpg"}
          alt={banner?.title || "banner"}
        />
      </div>
    </div>
  );
};

export { BannerItem };
