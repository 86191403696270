"use client";

import { Ratings, Table, TableBody } from "@/components/core-components";
import { useAppSelector } from "@/store";
import { displayValue, ICartItem } from "@/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const OrderTable = ({
  cartItems,
  orderDetails = false,
}: {
  cartItems: any[];
  orderDetails?: boolean;
}) => {
  return (
    <Table parentClassName="order_table" tableClassName="no-border">
      <TableBody>
        {cartItems?.length > 0 &&
          cartItems?.map((item, i) => {
            return (
              <tr key={i}>
                <td className="image product-thumbnail" width={"20%"}>
                  <Image
                    src={
                      orderDetails
                        ? item.product?.thumbnails?.[0]?.img
                        : item?.thumbnails?.[0]?.img
                    }
                    alt={item.title}
                    height={100}
                    width={100}
                  />
                </td>
                <td className="v-align-top">
                  <div className="d-flex flex-column">
                    <h6 className="w-160 mb-5 font-sm fw-600">
                      <Link
                        href={`/${item.slug || item.product.slug}`}
                        // prefetch
                      >
                        {item.title || item.product.title}
                      </Link>
                      <div className="product-rate-cover">
                        <Ratings rating={item.product.rating || item.rating} />
                      </div>
                    </h6>
                    <div className="d-flex flex-column gap-1 justify-content-start align-items-baseline">
                      <span className="text-sm">{`Qty: ${item.quantity}`}</span>
                      <span className="text-sm">{`${
                        orderDetails
                          ? item.product?.selected_weight
                          : item.weight
                      }${orderDetails ? item.product?.uom : item.uom}`}</span>
                    </div>
                  </div>
                </td>
                {/* <td className="v-align-top">
                  <h6 className="text-muted fw-600 pl-10 pr-10">{`${
                    orderDetails ? item.product?.selected_weight : item.weight
                  } ${orderDetails ? item.product?.uom : item.uom}`}</h6>
                </td>
                <td className="v-align-top">
                  <h6 className="text-muted fw-600 pl-10 pr-10">
                    {item.quantity}
                  </h6>
                </td> */}
                <td className="v-align-top" align="right">
                  <h4 className="fw-600 font-sm">
                    <span className="rupee-symbol">₹</span>
                    {displayValue(item.payable_amount)}
                  </h4>
                </td>
              </tr>
            );
          })}
      </TableBody>
    </Table>
  );
};

export { OrderTable };
