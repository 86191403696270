import React from "react";
import { CartSummary } from "../Cart";
import {
  displayValue,
  formatDateTime,
  IOrderAddress,
  IOrderDetail,
  IPayment,
  to2Decimal,
} from "@/utils";
import { Table, TableBody } from "@/components/core-components";

const OrderInfo = ({
  orderDetails,
  isOrderDetailPage,
  sectionclassName,
  cartItems,
  successfullPayment,
  shippingAddress,
  billingAddress,
}: {
  orderDetails: IOrderDetail;
  isOrderDetailPage: boolean;
  sectionclassName: string;
  cartItems: any[];
  successfullPayment: IPayment | undefined;
  shippingAddress: IOrderAddress | undefined;
  billingAddress: IOrderAddress | undefined;
}) => {
  const transactionDetails = JSON.parse(
    successfullPayment?.transaction_details || "{}"
  );

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="border p-md-4 p-15 border-radius cart-totals">
          <div className="row">
            {cartItems?.length === 0 ? null : isOrderDetailPage ? (
              <CartSummary
                cartItems={cartItems}
                title={"Order Summary"}
                parentClassName={`${sectionclassName} order-summary-section`}
                shipping_cost={orderDetails?.total_shipping || 0}
                discount={{
                  discount_code: orderDetails?.discount_code,
                  discount_value: orderDetails?.discount_amount,
                  total_discount: orderDetails?.total_discount,
                  discount_type: orderDetails?.discount_type,
                  productDiscount: [],
                }}
              />
            ) : (
              <div className={`${sectionclassName} order-summary-section`}>
                <CartSummary
                  cartItems={cartItems}
                  shipping_cost={orderDetails?.total_shipping || 0}
                  discount={{
                    discount_code: orderDetails?.discount_code,
                    discount_value: orderDetails?.discount_amount,
                    total_discount: orderDetails?.total_discount,
                    discount_type: orderDetails?.discount_type,
                    productDiscount: [],
                  }}
                />
              </div>
            )}
            <div
              className={`${sectionclassName} ${
                isOrderDetailPage
                  ? "order-summary-section"
                  : "payment-info-section"
              }`}
            >
              {isOrderDetailPage ? (
                <React.Fragment>
                  <div className="heading_s1 mb-3">
                    <h5>Payment Method</h5>
                  </div>
                  <div>
                    {successfullPayment &&
                    Object.keys(transactionDetails).length > 0 ? (
                      <Table tableClassName="mb-0">
                        <TableBody>
                          <tr>
                            <td className="cart_total_label" width={"50%"}>
                              Payment Method
                            </td>
                            <td
                              className="cart_total_amount text-end"
                              width={"50%"}
                            >
                              <span className="font-sm fw-500 text-dark">
                                {transactionDetails.method?.toLocaleUpperCase()}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label" width={"50%"}>
                              Transaction ID
                            </td>
                            <td
                              className="cart_total_amount text-end"
                              width={"50%"}
                            >
                              <span className="font-sm fw-500 text-dark">
                                {successfullPayment?.payment_gateway_id}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label" width={"50%"}>
                              Paid Amount
                            </td>
                            <td
                              className="cart_total_amount text-end"
                              width={"50%"}
                            >
                              <span className="font-sm fw-500 text-dark">
                                <span className="rupee-symbol">₹</span>
                                {displayValue(
                                  to2Decimal(transactionDetails.amount / 100)
                                )}
                              </span>
                            </td>
                          </tr>
                        </TableBody>
                      </Table>
                    ) : (
                      "Please Retry your Payment"
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <Table>
                  <TableBody>
                    <tr>
                      <td className="cart_total_label">Order ID</td>
                      <td className="cart_total_amount text-end">
                        <span className="font-sm fw-500 text-dark">
                          {orderDetails?.invoice_code}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">Ordered On</td>
                      <td className="cart_total_amount text-end text-wrap">
                        {formatDateTime(orderDetails?.created_at)}
                      </td>
                    </tr>
                    {successfullPayment && (
                      <tr>
                        <td className="cart_total_label">Transaction ID</td>
                        <td className="cart_total_amount text-end">
                          {successfullPayment?.payment_gateway_id}
                        </td>
                      </tr>
                    )}
                    {successfullPayment && (
                      <tr>
                        <td className="cart_total_label">Payment Method</td>
                        <td className="cart_total_amount text-end">
                          {successfullPayment?.payment_mode?.payment_mode}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
            <div className={`${sectionclassName} shipping-info-section mb-10`}>
              <div className="heading_s1 mb-2">
                <h5>Shipping Address</h5>
              </div>
              {shippingAddress && (
                <div>
                  <p>
                    {shippingAddress?.name}
                    <br />
                    {shippingAddress?.address_line_1}
                    {`${shippingAddress.address_line_2}, ${shippingAddress.city.city}, ${shippingAddress.state.state}, ${shippingAddress.country.country}, ${shippingAddress.postal_code}`}
                  </p>
                </div>
              )}
            </div>
            <div className={`${sectionclassName} billing-info-section`}>
              <div className="heading_s1 mb-2">
                <h5>Billing Address</h5>
              </div>
              {billingAddress && (
                <div>
                  <p>
                    {billingAddress?.name}
                    <br />
                    {billingAddress?.address_line_1}
                    {`${billingAddress.address_line_2}, ${billingAddress.city.city}, ${billingAddress.state.state}, ${billingAddress.country.country}, ${billingAddress.postal_code}`}
                  </p>
                  {/* <p>{`${billingAddress?.address_line_2}, ${billingAddress?.postal_code}`}</p> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrderInfo };
