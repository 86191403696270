"use client";

import { GET_AddressType } from "@/utils";
import React, { useEffect, useMemo } from "react";
import { AddAddressCard, AddressCard } from "./AddressCard";
import { setQuickView, useAppDispatch, useAppSelector } from "@/store";

const AddressList = ({ addresses }: { addresses: GET_AddressType[] }) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  useEffect(() => {
    dispatch(setQuickView({ state: false, data: null, type: "" }));
  }, [state.status, state.type]);

  return (
    <div className="tab-pane fade active show">
      <div className="row">
        <AddAddressCard />
        {addresses?.length > 0 &&
          addresses?.map((address, index) => (
            <AddressCard key={index} address={address} />
          ))}
      </div>
    </div>
  );
};

export { AddressList };
