"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CartSummary } from "../Cart";
import { OrderTable } from "./OrderTable";
import {
  fetchShippingCost,
  resetCartState,
  setAddresses,
  setQuickView,
  setShippingCost,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  BUTTON_CONSTANTS,
  calculateAllValues,
  IAddOrder,
  IAddOrderItem,
  ICartItem,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  to2Decimal,
  generateRazorpayOptions,
  Toast,
  TOAST_CONSTANTS,
  STATUSES,
  canPlaceOrder,
  constuctAddress,
  trackAddToCartWishlist,
  formatCartItems,
  applyProductSpecificDiscount,
  apiRequestClient,
} from "@/utils";
import { UserAddressList } from "./UserAddressList";
import { Button } from "@/components/core-components";
import { useRouter } from "next/navigation";
import Script from "next/script";
import parse from "html-react-parser";
import * as Sentry from "@sentry/nextjs";

const Checkout = ({
  cartItems: DBCartItems,
  addresses: DBAddresses,
  discount_code,
}: {
  cartItems: ICartItem[];
  addresses?: any[];
  discount_code?: string;
}) => {
  const dispatch = useAppDispatch();

  const { lastSelectedAddress, addresses } = useAppSelector(
    (state) => state.address
  );

  const { state } = useAppSelector((state) => state.common);

  const { appliedDiscount } = useAppSelector((state) => state.cart);

  const { proceedToCheckout } = useAppSelector((state) => state.order);

  const { shippingCost } = useAppSelector((state) => state.order);

  const router = useRouter();

  const [sameAddress, setSameAddress] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);

  const [addressAccordion, setAddressAccordion] = useState({
    shipping: {
      state: true,
      id:
        lastSelectedAddress ||
        DBAddresses?.find((item: any) => item.set_as_default === 1)?.id ||
        DBAddresses?.[0]?.id ||
        0,
    },
    billing: {
      state: false,
      id:
        lastSelectedAddress ||
        DBAddresses?.find((item: any) => item.set_as_default === 1)?.id ||
        DBAddresses?.[0]?.id ||
        0,
    },
  });

  const canProceedToCheckout = useMemo(
    () => canPlaceOrder(DBCartItems, addressAccordion),
    [addressAccordion.shipping.id, addressAccordion.billing.id]
  );

  const prepareCart = (cartItems: ICartItem[]) => {
    const { total_amount, sub_total, total_items, round_off } =
      calculateAllValues(cartItems);

    const shipping_address: any = constuctAddress(
      addresses?.find((address) => address.id === addressAccordion.shipping?.id)
    );

    const billing_address = constuctAddress(
      addresses?.find((address) => address.id === addressAccordion.billing?.id)
    );

    const apply_international_shipping =
      shipping_address[STATIC_CONSTANTS.INTERNATIONAL_SHIPPING.location] !==
      STATIC_CONSTANTS.INTERNATIONAL_SHIPPING.value;

    const new_cartItems = formatCartItems(
      cartItems.map((item: ICartItem) =>
        applyProductSpecificDiscount(item, appliedDiscount)
      ),
      apply_international_shipping
    );

    const shippingTax = to2Decimal(shippingCost - (shippingCost * 100) / 118);

    const roundOff =
      Math.round(
        total_amount - (appliedDiscount.total_discount || 0) + shippingCost
      ) -
      (total_amount - (appliedDiscount.total_discount || 0) + shippingCost);

    return {
      bill_amount: sub_total,
      total_shipping: shippingCost || 0,
      shipping_tax: shippingTax || 0,
      payable_amount: to2Decimal(
        total_amount -
          (appliedDiscount.total_discount || 0) +
          shippingCost +
          roundOff
      ),
      discount_amount: appliedDiscount.discount_value || 0,
      discount_code: appliedDiscount.discount_code,
      discount_type: appliedDiscount.discount_type,
      total_quantity: total_items,
      roundoff: Number(roundOff.toFixed(2)),
      remarks: "",
      total_discount: appliedDiscount.total_discount || 0,
      total_tax: to2Decimal(
        new_cartItems.reduce(
          (acc, item: IAddOrderItem) => acc + item.total_tax,
          0
        ) + shippingTax
      ),
      products: new_cartItems,
      addresses: [
        {
          ...shipping_address,
          address_type: "shipping",
        },
        {
          ...billing_address,
          address_type: "billing",
        },
      ],
    };
  };

  const placeOrder = async () => {
    setLoading(true);

    const order_data: IAddOrder = prepareCart(DBCartItems);

    try {
      const order_response = await apiRequestClient({
        url: "/api/order/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data: order_data,
      });

      if (order_response.error) {
        setLoading(false);
        Toast({ message: order_response.error, type: TOAST_CONSTANTS.ERROR });
      }

      dispatch(resetCartState());

      try {
        if (order_response) {
          const options = generateRazorpayOptions(
            {
              amount: order_response.result.payable_amount * 100,
              id: order_response.result?.payment_data?.[0]
                ?.payment_gateway_order,
              notes: {
                order_id: order_response.result.id,
                store_id: order_response.result.store,
                customer_name: order_response.result.customer.name,
                customer_email: order_response.result.customer.email,
                customer_contact_number:
                  order_response.result.customer.contact_number,
              },
            },
            paymentFailure,
            router,
            dispatch,
            false,
            setLoading
          );

          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        }
      } catch (error: any) {
        Sentry.captureException(new Error(JSON.stringify(error)));
        setLoading(false);
        Toast({
          message: "Error occured while placing order",
          type: TOAST_CONSTANTS.ERROR,
        });
      }
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      setLoading(false);
      Toast({
        message: "Error occured while placing order",
        type: TOAST_CONSTANTS.ERROR,
      });
    }
  };

  const trackBegincheckoutEvent = useCallback(() => {
    const { total_amount } = calculateAllValues(DBCartItems);

    const products = formatCartItems(DBCartItems);

    const eventItems = products.map((productItem, index) => ({
      item_id: DBCartItems[index].product_code,
      item_list_id: "checkout_cart",
      item_name: DBCartItems[index].title,
      item_brand: "Avarya",
      item_category: DBCartItems[index].category,
      item_category2: DBCartItems[index].sub_category,
      quantity: productItem.quantity,
      price: productItem.unit_price,
    }));

    trackAddToCartWishlist("begin_checkout", {
      currency: "INR",
      value: to2Decimal(total_amount - appliedDiscount.total_discount),
      items: eventItems,
    });
  }, [DBCartItems]);

  useEffect(() => {
    if (DBAddresses && DBAddresses?.length > 0) {
      dispatch(
        fetchShippingCost({
          cartItems: DBCartItems,
          addressId: addressAccordion.shipping.id,
        })
      );
    } else {
      dispatch(
        setQuickView({
          state: true,
          data: setAddressAccordion,
          type: "address",
        })
      );
    }
  }, [addressAccordion.shipping.id]);

  useEffect(() => {
    trackBegincheckoutEvent();

    dispatch(setAddresses(DBAddresses));

    // discount_code && dispatch(applyDiscount({ code: discount_code }));

    // dispatch(
    //   fetchAddresses({
    //     setAddress: setAddressAccordion,
    //     cartItems: DBCartItems,
    //   })
    // );
    return () => {
      dispatch(setShippingCost(0));
      dispatch(setQuickView({ data: null, state: false, type: "" }));
    };
  }, []);

  return (
    <React.Fragment>
      <Script
        id="razorpay-checkout-js"
        src={STATIC_CONSTANTS.RAZORPAY_CONSTANTS.CHECKOUT_URL}
      />
      <section>
        <div className="container pt-30">
          <div className="row">
            <div className="col-lg-8 mb-15">
              <div className="section-title style-2 mb-0">
                <h3>Checkout</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="shipping-address">
                <UserAddressList
                  type="shipping"
                  title="Shipping Address"
                  address={addressAccordion}
                  setAddress={setAddressAccordion}
                  setSameAddress={setSameAddress}
                />
              </div>

              <div className="custome-checkbox mb-20">
                <input
                  type="checkbox"
                  className="form-check-input text-xs"
                  name="isBillingSame"
                  value="isBillingSame"
                  checked={sameAddress}
                  onClick={() => {
                    setAddressAccordion({
                      shipping: {
                        ...addressAccordion.shipping,
                        state: false,
                      },
                      billing: {
                        state: true,
                        id:
                          lastSelectedAddress ||
                          addresses?.find((item) => item.set_as_default === 1)
                            ?.id ||
                          addresses?.[0]?.id ||
                          0,
                      },
                    });
                    setSameAddress(!sameAddress);
                  }}
                  id="isBillingSame"
                />
                <label
                  className="form-check-label font-xl user-select-none"
                  htmlFor="isBillingSame"
                >
                  Same as Shipping Address
                </label>
              </div>

              {!sameAddress && (
                <div className="billing-address">
                  <UserAddressList
                    type="billing"
                    title="Billing Address"
                    address={addressAccordion}
                    setAddress={setAddressAccordion}
                    setSameAddress={setSameAddress}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-5">
              {/* <div className="ml-30 mb-30">
                <ApplyDiscount />
              </div> */}
              <div className="border p-15 cart-totals ml-30 mb-30">
                <CartSummary
                  title="Order Summary"
                  discount={appliedDiscount}
                  cartItems={DBCartItems}
                  shipping_cost={shippingCost}
                  showTotalItems={true}
                />
                <OrderTable cartItems={DBCartItems} />
                {/* <PaymentMethod /> */}
                <div className="text-xs text-start pb-10">
                  {parse(
                    `By placing your order, you agree to Avarya's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button
                      className="btn btn-sm w-100 btn-fill-out btn-block"
                      text={"Place Order"}
                      disabled={
                        DBCartItems?.length === 0 ||
                        !(shippingCost > 0) ||
                        !canProceedToCheckout ||
                        // proceedToCheckout ||
                        (state.status === STATUSES.LOADING &&
                          state.type === "shipping-cost")
                      }
                      loading={loading}
                      type={BUTTON_CONSTANTS.BUTTON}
                      onClick={() => {
                        if (addresses?.length === 0) {
                          dispatch(
                            setQuickView({
                              type: "address",
                              state: true,
                              data: null,
                            })
                          );
                        }
                        if (canProceedToCheckout) {
                          placeOrder();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export { Checkout };
