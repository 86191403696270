import { Ratings } from "@/components/core-components";
import { calculatePrice } from "@/utils";
import Image from "next/image";
import Link from "next/link";

const ProductGridList = ({
  title,
  products,
  delay,
  widgetIndex,
}: {
  title: string;
  products: any[];
  delay: string;
  widgetIndex: number;
}) => {
  return (
    <div
      className={`product-grid-list-item col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-xs-15 mb-md-0 wow animate__animated animate__fadeInUp`}
      data-wow-delay={delay}
    >
      <h2 className="section-title style-1 pb-0 animated animated">{title}</h2>
      <div className="product-list-small animated animated">
        {products.map((product, i) => (
          <article
            className="row hover-up article-card"
            key={`${product?.title}-${i}`}
          >
            <figure className="col-md-4 col-4 mb-0">
              <Link href="/[slug]" as={`/${product.slug}`}>
                <Image
                  src={product?.thumbnails?.[0]?.img}
                  alt={product.title}
                  height={200}
                  width={200}
                  className="img-fluid h-100 w-100"
                />
              </Link>
            </figure>
            <div className="col-md-8 col-8 mb-0 article-card-content">
              <h3 className="product-grid-list-item-title">
                <Link href="/[slug]" as={`/${product.slug}`}>
                  {product.title}
                </Link>
              </h3>
              <div className="product-rate-cover">
                <Ratings rating={product.rating} />
              </div>
              <div className="product-price d-flex flex-row align-items-center mb-5">
                <span className="d-flex flex-row align-items-start">
                  <span className="rupee-symbol">₹</span>
                  {`${
                    product.selling_uom === "pcs"
                      ? product.price
                      : calculatePrice(
                          1000,
                          product.price,
                          product?.weights?.[0]?.value
                        )
                  } `}
                </span>
                <span className="old-price">
                  {product.oldPrice > 0 ? (
                    <span>
                      <span className="rupee-symbol">₹</span>
                      {product.oldPrice}
                    </span>
                  ) : null}
                </span>
              </div>
              {/* <div className="product-price">
                <span>
                  <span className="rupee-symbol">₹</span>
                  {product.selling_uom === "pcs"
                    ? product.price
                    : calculatePrice(
                        1000,
                        product.price,
                        product?.weights?.[0]?.value
                      )}&nbsp;
                </span>
                <span className="old-price">
                  {product.oldPrice && `₹${product.oldPrice}`}
                </span>
              </div> */}
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default ProductGridList;
