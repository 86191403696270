"use client";

import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import Image from "next/image";
import heart from "../../../../../public/assets/imgs/theme/icons/icon-heart.svg";
import heartFilled from "../../../../../public/assets/imgs/theme/icons/icon-heart-filled.svg";
import {
  API_ROUTES,
  calculatePrice,
  generateGtagEventPayload,
  handleCart,
  IProduct,
  STATUSES,
  strip_html_tags,
  Toast,
  trackAddToCartWishlist,
} from "@/utils";
import { Button, CustomSelect, Ratings } from "@/components/core-components";
import {
  addPendingItem,
  addToWishlist,
  incrementCartItemsCounter,
  removeFromWishlist,
  setOffCanvas,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useRouter } from "next/navigation";

const ProductItem = ({
  type,
  index,
  product,
  hasDesc,
  deleteBtn,
  largeCard = false,
  widgetIndex,
}: {
  type?: string;
  index: number;
  product: IProduct;
  hasDesc: boolean;
  deleteBtn?: (value: any) => void;
  largeCard?: boolean;
  widgetIndex: number;
}) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { status: wishlistStatus, wishlist } = useAppSelector(
    (state) => state.wishlist
  );

  const router = useRouter();

  const [weight, setWeight] = useState<
    {
      id: number;
      value: number;
      label: string;
      uom: string;
    }[]
  >([]);

  const [selectedItem, setSelectedItem] = useState<number>();

  const canAddtoWishlist = wishlist.find(
    (item: any) => item.id === product.id
  )?.wishlist_id;

  const currentPrice = useMemo(() => {
    return product.selling_uom === "pcs"
      ? product.price
      : calculatePrice(
          1000,
          product.price,
          weight?.[0]?.value || product?.weights?.[0]?.value
        );
  }, [weight, product.id]);

  const gTagEventData = useMemo(() => {
    return generateGtagEventPayload({
      product,
      currentPrice,
      quantity: 1,
      type: type || "product_item",
      index,
      weight: weight[0],
    });
  }, [weight]);

  const AddToCart = (product: IProduct) => {
    handleCart({
      dispatch: dispatch,
      is_logged_in: logged_in_user || access_token,
      product: product,
      selected_weight: weight,
      onSuccess() {
        dispatch(setOffCanvas({ state: true, component: "cart" }));
        dispatch(incrementCartItemsCounter());
        trackAddToCartWishlist("add_to_cart", gTagEventData);
      },
    });
  };

  const handleWishlist = (product: any) => {
    if (logged_in_user || access_token) {
      dispatch(
        addToWishlist({
          product_id: product.id,
          onSucces: () => {
            Toast({
              message: "Added to Wishlist !",
              icon: <i className="fi-rs-heart"></i>,
            });
            trackAddToCartWishlist("add_to_wishlist", gTagEventData);
          },
        })
      );
    } else {
      dispatch(addPendingItem(product.id));
      router.push(API_ROUTES.LOGIN);
    }
  };

  useEffect(() => {
    setWeight([product?.weights?.[0]]);
  }, [product?.id]);

  return (
    <div
      className={`product-cart-wrap ${
        deleteBtn ? "wishlist-card" : largeCard ? "single-product-3" : ""
      }`}
    >
      {/* <div className='product-img-action-wrap'> */}
      {/* <div className='product-img product-img-zoom'> */}
      <Link
        href={`/${product.slug}`}
        className="product-img product-img-zoom d-block"
      >
        {/* {largeCard && <div className='overlay bottom-0'></div>} */}
        <Image
          width={200}
          height={200}
          className="w-100 h-100 default-img"
          src={
            product?.thumbnails?.[0]?.img ||
            product?.images?.[0]?.img ||
            "/assets/images/products/product-1-1.jpg"
          }
          alt={product.title}
          loading={widgetIndex > 1 ? "lazy" : "eager"}
        />
      </Link>
      {/* </div> */}

      <div className="product-badges product-badges-position product-badges-mrg">
        {!largeCard && product.discount.value > 0 && (
          <span className="sale">Sale</span>
        )}
        {!largeCard && product.discount.value >= 5 && (
          <span className="bg-yellow">
            {`${
              product.discount.type === "percentage"
                ? `${product.discount.value}%`
                : `₹${product.discount.value}`
            }`}
          </span>
        )}
      </div>
      {/* </div> */}

      <div
        className={`product-content-wrap ${
          largeCard ? "large-card-content text-center" : ""
        }`}
      >
        <h3 className={`${largeCard ? "mb-5" : ""}`}>
          <Link
            href={`/${product.slug}`}
            onClick={() => {
              trackAddToCartWishlist("view_item", gTagEventData);
            }}
          >
            {product.title}
          </Link>
        </h3>

        {largeCard && (
          <div className="product-card-bottom mt-0 mb-5">
            <div className="product-price pt-0">
              {product.oldPrice > 0 ? (
                <span className="old-price mr-10">
                  <span className="rupee-symbol">₹</span>
                  {product.oldPrice}
                </span>
              ) : null}
              <span>
                <span className="rupee-symbol">₹</span>
                {`${currentPrice} `}
              </span>
            </div>
          </div>
        )}

        <div className="d-flex flex-row justify-content-between align-items-center">
          {!largeCard && (
            <CustomSelect
              aria-label="product-weight"
              id={`product-item-${widgetIndex}-${index}-${product.title.replace(
                /\s/g,
                "-"
              )}-weight`}
              onlyLabel={product.weights?.length === 1 || false}
              options={product.weights?.map((w: any) => ({
                value: w.value,
                label: `${w.label}${w.uom}`,
              }))}
              selectClassName={
                product.weights?.some((w: any) => w.uom === "g") ? "pr-18" : ""
              }
              name="weight"
              onChange={(e: any) => {
                setWeight(
                  product.weights.filter(
                    (w: any) => w.value === Number(e.target.value)
                  )
                );
              }}
            />
          )}
          <div className="product-rate-cover">
            <Ratings rating={product.rating} />
          </div>
        </div>

        {hasDesc && !largeCard && (
          <p className="font-xs product-description line-height-1-5">
            {strip_html_tags(product.description)}
          </p>
        )}

        {!largeCard && (
          <React.Fragment>
            {/* <div className='product-card-bottom mt-0 flex-column'> */}
            <div className="d-flex flex-row-align-items-center justify-content-between">
              <div className="product-price d-flex flex-row align-items-center mb-5">
                <span className="d-flex flex-row align-items-start">
                  <span className="rupee-symbol">₹</span>
                  {`${currentPrice} `}
                </span>
                <span className="old-price">
                  {product.oldPrice > 0 ? (
                    <span>
                      <span className="rupee-symbol">₹</span>
                      {product.oldPrice}
                    </span>
                  ) : null}
                </span>
              </div>
              {type !== "wishlist" && (
                <a
                  className="w-max-content quickview-link text-center wishlist-link-icon"
                  onClick={() => {
                    if (product.wishlist_added || canAddtoWishlist) {
                      dispatch(
                        removeFromWishlist({
                          wishlist_id: canAddtoWishlist,
                          onSuccess: () => {
                            Toast({
                              message: "Removed from wishlist",
                              icon: <i className="fi-rs-heart"></i>,
                            });
                          },
                        })
                      );
                      // Toast(
                      //   "Already added in wishlist",
                      //   TOAST_CONSTANTS.INFO,
                      //   <i className="fi-rs-heart"></i>
                      // );
                    } else {
                      handleWishlist(product);
                    }
                  }}
                >
                  {/* <i className="fi-rs-heart"></i> */}
                  <Image
                    width={20}
                    height={20}
                    className="svgInject filter-black-primary"
                    alt="wishlist"
                    src={!canAddtoWishlist ? heart : heartFilled}
                  />
                </a>
              )}
            </div>
            {/* <div className='add-to-cart-buttons d-flex justify-content-between'> */}
            {/* Delete Item */}
            {deleteBtn && (
              // <div className='add-cart mb-10 w-100 border-none'>
              <Button
                className="btn add w-100 text-center bg-red text-white mb-1"
                text="Delete"
                type={"button"}
                loading={
                  selectedItem === index && wishlistStatus === STATUSES.LOADING
                }
                onClick={() => {
                  deleteBtn(product);
                  setSelectedItem(index);
                }}
              />
              // </div>
            )}
            {/* </div> */}
            {/* <div className='add-cart d-flex gap-2 mt-5  '> */}
            <a
              className="add w-100 text-center"
              onClick={() => {
                AddToCart(product);
              }}
            >
              {(logged_in_user || access_token) &&
              state.status === STATUSES.LOADING &&
              state.type === `add-cartItem-${product.id}` ? (
                <React.Fragment>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    aria-hidden="true"
                  />
                  &nbsp;
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <i className="fi-rs-shopping-cart mr-5"></i>
                  Add to Cart
                </React.Fragment>
              )}
            </a>
            {/* </div> */}
            {/* </div> */}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export { ProductItem };
