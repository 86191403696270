"use client";

import React, { useEffect } from "react";
import { OrderItems } from "./OrderItems";
import {
  setOffCanvas,
  setWholeCart,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { Button } from "@/components/core-components";
import { useRouter } from "next/navigation";
import { API_ROUTES, calculateAllValues, isTokenValid } from "@/utils";
import jwtDecode from "jwt-decode";
import Image from "next/image";

type Section = {
  title: string;
  component: React.JSX.Element;
};

const CartOffCanvas = ({
  tokens,
}: {
  tokens: {
    access_token: string;
    refresh_token: string;
  };
}) => {
  const dispatch = useAppDispatch();
  const { cartItems } = useAppSelector((state) => state.cart);
  const router = useRouter();
  const { total_items } = calculateAllValues(cartItems);

  const sections = [
    {
      title: "Cart Items",
      component: <OrderItems showQuantity={true} cartItems={cartItems} />,
    },
  ];

  const renderSections = (sections: Section[]) => {
    return sections.map((section, index) => (
      <div key={index} className="w-100 mb-md-0 mb-10 mini-cart">
        <span className="section-title gap-2 align-items-center justify-content-start font-xl fw-600 text-extra-dark mb-15 pb-0 pr-15">
          <Button
            type="button"
            className="closeButton mb-5"
            onClick={() => {
              dispatch(setOffCanvas({ state: false, component: null }));
            }}
            text={
              <Image
                width={16}
                height={16}
                src="/assets/imgs/theme/icons/icon-close.png"
                alt="Close"
              />
            }
          />
          {section.title}
          <span className="text-muted font-md">{`(${total_items} item${
            total_items > 1 ? "s" : ""
          })`}</span>
        </span>
        <div
          className="product-list-small overflow-y-scroll"
          style={{ height: `calc(100vh - 175px)` }}
        >
          {section.component}
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (tokens.access_token && isTokenValid(jwtDecode(tokens.access_token))) {
      dispatch(setWholeCart({}));
    }
  }, []);

  return (
    <div className="d-flex flex-column gap-1">
      {renderSections(sections)}
      <div className="d-flex flex-column pr-15 gap-2">
        <Button
          text="Checkout"
          disabled={cartItems?.length === 0}
          type="button"
          className="btn btn-sm w-100"
          onClick={() => {
            router.push(API_ROUTES.CHECKOUT);
            dispatch(setOffCanvas({ state: false, component: "cart" }));
          }}
        />
        <Button
          text="View Cart"
          type="button"
          className="btn btn-sm w-100"
          onClick={() => {
            router.push("/cart");
            dispatch(setOffCanvas({ state: false, component: "cart" }));
          }}
        />
      </div>
    </div>
  );
};

export { CartOffCanvas };
