import {
  apiRequestClient,
  customRevalidateTag,
  IAddress,
  POST_PUT_AddressType,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { setCommonState, setQuickView } from "./commonSlice";
import { FormikHelpers } from "formik";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import * as Sentry from "@sentry/nextjs";
import { RootState } from "../store";

const initialState = {
  addresses: [] as IAddress[],
  addressDetail: {} as IAddress,
  lastSelectedAddress: 0,
  status: STATUSES.IDLE as string,
  error: null as null | string,
};

export const fetchAddresses = createAsyncThunk(
  "account/fetchAddresses",
  async (
    {
      setAddress,
      cartItems,
    }: {
      setAddress: React.Dispatch<
        React.SetStateAction<{
          shipping: { state: boolean; id: number };
          billing: { state: boolean; id: number };
        }>
      >;
      cartItems: any[];
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      address: { lastSelectedAddress },
    } = getState() as RootState;

    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "fetch-addresses" })
    );
    try {
      const response: any = await apiRequestClient({
        url: `/api/account/addresses/`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      // dispatch(
      //   fetchShippingCost({
      //     cartItems: cartItems,
      //     addressId:
      //       lastSelectedAddress ||
      //       response.result?.results?.find(
      //         (item: any) => item.set_as_default === 1
      //       )?.id ||
      //       response.result?.results?.[0]?.id ||
      //       0,
      //   })
      // );

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "fetch-addresses",
        })
      );

      return response.result?.results || [];
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "fetch-addresses",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAddressDetails = createAsyncThunk(
  "auth/fetchAddressDetails",
  async (
    {
      address_id,
    }: {
      address_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(`/api/account/addresses/${address_id}/`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res?.json());

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const addAddress = createAsyncThunk(
  "auth/addAddress",
  async (
    {
      data,
      actions,
      router,
      modal = false,
      setAddress,
    }: {
      data: POST_PUT_AddressType;
      actions: FormikHelpers<any>;
      router: AppRouterInstance;
      modal: boolean;
      setAddress?: React.Dispatch<
        React.SetStateAction<{
          shipping: { state: boolean; id: number };
          billing: { state: boolean; id: number };
        }>
      >;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "add-address" }));
    try {
      const response = await apiRequestClient({
        url: "/api/account/addresses",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      if (modal) {
        dispatch(
          setQuickView({
            state: false,
            data: null,
            type: "",
          })
        );

        setAddress &&
          setAddress({
            shipping: { id: response.result?.id, state: true },
            billing: { id: response.result?.id, state: false },
          });
      } else {
        router.back();
      }

      actions.resetForm();

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "add-address" }));

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "add-address" }));
    }
  }
);

export const updateAddress = createAsyncThunk(
  "auth/updateAddress",
  async (
    {
      data,
      actions,
      router,
      modal = false,
      setAddress,
    }: {
      data: POST_PUT_AddressType;
      actions: FormikHelpers<any>;
      router: AppRouterInstance;
      modal: boolean;
      setAddress?: React.Dispatch<
        React.SetStateAction<{
          shipping: { state: boolean; id: number };
          billing: { state: boolean; id: number };
        }>
      >;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `update-address-${data.id}`,
      })
    );
    try {
      const response = await apiRequestClient({
        url: `/api/account/addresses/${data.id}`,
        method: REQUEST_METHOD.PUT,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      if (modal) {
        dispatch(
          setQuickView({
            state: false,
            data: null,
            type: "",
          })
        );
      } else {
        router.back();
      }

      actions.resetForm();

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `update-address-${data.id}`,
        })
      );

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `update-address-${data.id}`,
        })
      );
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "auth/deleteAddress",
  async (
    {
      address_id,
    }: {
      address_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(`/api/account/addresses/${address_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res?.json());

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const setLastSelectedAddress = createAsyncThunk(
  "address/setLastSelectedAddress",
  async (
    {
      id,
    }: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return id;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const setAsDefaultAddress = createAsyncThunk(
  "auth/setAsDefaultAddress",
  async (
    {
      data,
    }: {
      data: {
        address_id: number;
      };
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "set-default-address" })
    );
    try {
      const response = await fetch(
        `/api/account/addresses/set_as_default/?address=${data.address_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
          },
        }
      ).then((res) => res?.json());

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "set-default-address",
        })
      );

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "set-default-address",
        })
      );
      rejectWithValue(error.message);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    setAdddressDetail: (state, action) => {
      state.addressDetail = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAddressDetails.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAddressDetails.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.addressDetail = action.payload.result;
      })
      .addCase(addAddress.fulfilled, (state, action: any) => {
        state.addresses.unshift(action.payload.result);
        state.addresses = state.addresses.slice(0, 10);
      })
      .addCase(updateAddress.fulfilled, (state, action: any) => {
        const index = state.addresses.findIndex(
          (address) => address.id === action.payload.result.id
        );
        if (index !== -1) {
          state.addresses[index] = action.payload.result;
        }
      })
      .addCase(setLastSelectedAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.lastSelectedAddress = action.payload;
        }
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.addresses = action.payload;
        if (action.meta.arg.setAddress) {
          action.meta.arg.setAddress({
            shipping: {
              state: true,
              id:
                state.lastSelectedAddress ||
                action.payload?.find((item: any) => item.set_as_default === 1)
                  ?.id ||
                action.payload?.[0]?.id ||
                0,
            },
            billing: {
              state: false,
              id:
                state.lastSelectedAddress ||
                action.payload?.find((item: any) => item.set_as_default === 1)
                  ?.id ||
                action.payload?.[0]?.id ||
                0,
            },
          });
        }
      })
      .addMatcher(
        isAnyOf(addAddress.fulfilled, updateAddress.fulfilled),
        (state, action) => {
          if (action.meta.arg.setAddress) {
            action.meta.arg.setAddress({
              shipping: {
                state: true,
                id:
                  state.lastSelectedAddress ||
                  state.addresses?.find(
                    (item: any) => item.set_as_default === 1
                  )?.id ||
                  state.addresses?.[0]?.id ||
                  0,
              },
              billing: {
                state: false,
                id:
                  state.lastSelectedAddress ||
                  state.addresses?.find(
                    (item: any) => item.set_as_default === 1
                  )?.id ||
                  state.addresses?.[0]?.id ||
                  0,
              },
            });
          }
        }
      )
      .addMatcher(
        isAnyOf(addAddress.fulfilled, updateAddress.fulfilled),
        (_, action: any) => {
          Toast({
            message: action.payload.message,
            type: TOAST_CONSTANTS.SUCCESS,
          });
        }
      )
      .addMatcher(
        isAnyOf(
          addAddress.fulfilled,
          updateAddress.fulfilled,
          deleteAddress.fulfilled,
          setAsDefaultAddress.fulfilled
        ),
        () => {
          customRevalidateTag("addresses");
        }
      );
  },
});

export const { setAddresses, setAdddressDetail } = addressSlice.actions;

export default addressSlice.reducer;
