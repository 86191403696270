"use client";

import {
  setMaxPageLimitState,
  setMinPageLimitState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { GENERAL_CONSTANTS } from "./constants";
import { Bounce, toast, ToastPosition, TypeOptions } from "react-toastify";

export function usePreviousRoute(): string | null {
  const pathname = usePathname();
  const prevPathRef = useRef<string | null>(null);

  useEffect(() => {
    prevPathRef.current = pathname;
  }, [pathname]);

  return prevPathRef.current;
}

export function usePagination() {
  const searchParams = useSearchParams();
  const { push, replace } = useRouter();
  const pathname = usePathname();

  const [search, setSearch] = useState(searchParams.get("query") || "");
  const currentPage = Number(searchParams.get("page")) || 1;

  const { maxPageLimit, minPageLimit } = useAppSelector(
    (state) => state.common
  );

  useEffect(() => {
    // return () => {
    //   setSearch("");
    // };
  }, []);

  const params = new URLSearchParams(searchParams);
  const dispatch = useAppDispatch();

  const handlePageUpdate = (page_no: number = 1) => {
    params.set("page", page_no.toString());

    push(`?${params.toString()}`, { scroll: false });
  };

  const onNextClick = (page_no: number) => {
    if (page_no > maxPageLimit) {
      dispatch(
        setMaxPageLimitState(maxPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
      dispatch(
        setMinPageLimitState(minPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
    }
    handlePageUpdate(page_no);
  };

  const onPreviousClick = (page_no: number) => {
    if (page_no % GENERAL_CONSTANTS.PER_PAGE_LIMIT === 0) {
      dispatch(
        setMaxPageLimitState(maxPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
      dispatch(
        setMinPageLimitState(minPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
    }
    handlePageUpdate(page_no);
  };

  const handleSearch = (search: string) => {
    params.set("page", "1");
    setMaxPageLimitState(GENERAL_CONSTANTS.PER_PAGE_LIMIT);
    setMinPageLimitState(GENERAL_CONSTANTS.PER_PAGE_LIMIT);
    if (search) {
      params.set("query", search);
    } else {
      params.delete("page");
      params.delete("query");
    }

    replace(`${pathname}?${params.toString()}`);
  };

  const handleAdditionalFilter = (
    params_key: string = "",
    params_value: string = ""
  ) => {
    if (params_value) {
      params.set(params_key, params_value);
    } else {
      params.delete(params_key);
    }
    replace(`${pathname}?${params.toString()}`, { scroll: false });
  };

  const handleAllFilterOnce = (value: any) => {
    if (value && Object.keys(value).length > 0) {
      for (let key of Object.keys(value)) {
        if (value[key]) {
          params.set(key, `${value[key]}`);
        } else {
          params.delete(key);
        }
      }
      replace(`${pathname}?${params.toString()}`, { scroll: false });
    } else {
      replace(`${pathname}`, { scroll: false });
    }
  };

  const handleKeyDownClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  return {
    updatePage: handlePageUpdate,
    updateSearch: handleSearch,
    handleEnterClick: handleKeyDownClick,
    search,
    setSearch,
    currentPage,
    searchParams,
    additionalFilter: handleAdditionalFilter,
    handleAllFilterOnce,
    onNextClick,
    onPreviousClick,
  };
}

export function useClickOutside({
  ref,
  callback,
}: {
  ref: React.MutableRefObject<any>;
  callback: () => void;
}) {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useWindowDimensions() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize(); // Get initial window size

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return windowSize;
}

export const Toast = ({
  message,
  type,
  icon,
  position = toast.POSITION.TOP_RIGHT,
  closeDuration = 3000,
}: {
  message: string;
  type?: TypeOptions;
  icon?: React.ReactNode;
  position?: ToastPosition;
  closeDuration?: number;
}) => {
  toast(message, {
    icon: icon,
    position: position,
    autoClose: closeDuration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
    transition: Bounce,
  });
};
