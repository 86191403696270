"use client";

import {
  transformDataToBannerConfig,
  transformDataToListingConfig,
  transformDataToMultipleListingConfig,
  transformDataToProductDealsConfig,
  WIDGET_CONSTANTS,
} from "@/utils";
import { notFound } from "next/navigation";

import parse from "html-react-parser";
import React, { MutableRefObject } from "react";
import { StructuredData } from "./StructuredData";
import {
  BannerWidget,
  Deals,
  ListingWidet,
  MultipleListing,
} from "./group-components/Widget";

const Widget = ({
  pageData,
  loadingRef,
}: {
  pageData?: any;
  loadingRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  if (!pageData || (Array.isArray(pageData) && pageData.length === 0)) {
    return notFound();
  }

  const renderWidget = (widget: any, index: number) => {
    const commonProps = {
      widgetIndex: index,
      pageDetails: {
        display_name: pageData.display_name,
        short_summary: pageData.short_summary,
      },
    };

    const { widget_type } = widget;

    if (widget_type === WIDGET_CONSTANTS.BANNER) {
      return (
        <BannerWidget
          config={transformDataToBannerConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.LISTING) {
      let listingConfig = transformDataToListingConfig(widget);

      return (
        <ListingWidet
          config={listingConfig}
          type={widget.type}
          noOfWidget={pageData?.content?.length}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.MULTIPLE_LISTING) {
      return (
        <MultipleListing
          config={transformDataToMultipleListingConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.DEALS) {
      return (
        <Deals
          config={transformDataToProductDealsConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.STATIC) {
      return parse(widget?.content?.[0]?.value);
    }

    return notFound();
  };

  return (
    <React.Fragment key={pageData.id}>
      {pageData.content.map((item: any, index: number) =>
        renderWidget(
          {
            page_id: pageData.id,
            id: item.id,
            widget_id: item.widget.id,
            widget_type: item.widget.widget_type,
            content: item.content,
            type: item.pages
              ? "static"
              : item.product_category_cms
              ? "category"
              : item.product_subcategory_cms
              ? "sub_category"
              : "product",
          },
          index
        )
      )}
      <div ref={loadingRef} />
    </React.Fragment>
  );
};

export { Widget };
