"use client";

import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import Image from "next/image";

import user from "../../public/assets/imgs/theme/icons/icon-user.svg";

import heart from "../../public/assets/imgs/theme/icons/icon-heart.svg";
import cart from "../../public/assets/imgs/theme/icons/icon-cart.svg";

import youtube from "../../public/assets/imgs/theme/icons/icon-youtube-white.svg";
import instagram from "../../public/assets/imgs/theme/icons/icon-instagram-white.svg";
import facebook from "../../public/assets/imgs/theme/icons/icon-facebook-white.svg";

import {
  fetchUserDetails,
  logoutUser,
  RootState,
  setOffCanvas,
  toggleMobileMenuState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { Search } from "./core-components";
import { usePathname, useRouter } from "next/navigation";
import jwtDecode from "jwt-decode";
import { API_ROUTES, isTokenValid } from "@/utils";

const Header = ({
  transparent = false,
  data: header,
  tokens,
}: {
  transparent?: boolean;
  data: any;
  tokens: {
    access_token: string;
    refresh_token: string;
  };
}) => {
  const dispatch = useAppDispatch();

  const paths = usePathname();
  const pathNames = paths.split("/").filter((path) => path);

  const { logged_in_user } = useAppSelector((state) => state.auth);

  const { toggleMobileMenu } = useAppSelector((state) => state.common);

  const { wishlistCounter } = useAppSelector(
    (state: RootState) => state.wishlist
  );

  const { cartItemsCounter, cartItems } = useAppSelector(
    (state: RootState) => state.cart
  );

  const router = useRouter();

  const [scroll, setScroll] = useState(false);

  const cartRedirect = ["account", "cart", "wishlist", "checkout", "success"];

  const toggleClick = () => {
    dispatch(toggleMobileMenuState());
    if (toggleMobileMenu) {
      document.querySelector("body")?.classList.remove("mobile-menu-active");
    } else {
      document.querySelector("body")?.classList.add("mobile-menu-active");
    }
  };

  const renderHeader = ({
    data,
    is_mega_menu = false,
  }: {
    data: any;
    is_mega_menu?: boolean;
  }) => {
    type TMenuItem = {
      id: number;
      menu_item_title: string;
      slug: string;
      is_mega_menu: number;
      mega_menu: any;
      children: any[];
    };

    return (
      <ul className={`${is_mega_menu ? "mt-0 row" : ""}`}>
        {data
          ? data.map((menu: TMenuItem, index: number) => {
              return (
                <li
                  id={`${
                    menu.is_mega_menu ? `mega-menu` : `menu-item-${index}`
                  }`}
                  key={index}
                  className={`${
                    is_mega_menu
                      ? "sub-mega-menu sub-mega-menu-width-10 col-lg-2"
                      : menu.is_mega_menu
                      ? "position-static"
                      : ""
                  }`}
                >
                  <Link
                    href={`/${menu.slug}`}
                    className={`${
                      is_mega_menu ? "menu-title heading-underline" : ""
                    }`}
                  >
                    {menu.menu_item_title}
                    {(menu.children?.length > 0 || menu.is_mega_menu) &&
                    !is_mega_menu ? (
                      <i className="fi-rs-angle-down"></i>
                    ) : null}
                  </Link>
                  {menu.is_mega_menu ? (
                    <ul className="mega-menu">
                      <li className="sub-mega-menu-width-66">
                        {renderHeader({
                          data: menu.mega_menu,
                          is_mega_menu: true,
                        })}
                      </li>
                      <li className="sub-mega-menu sub-mega-menu-width-34">
                        <div className="menu-banner-wrap">
                          <a href="#">
                            <Image
                              width={400}
                              height={300}
                              className="img-fluid h-100 w-100"
                              src="https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/mega-menu-banner.webp"
                              alt="mega-menu-image"
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                  ) : null}
                  {menu.children?.length > 0 ? (
                    <ul className={`${is_mega_menu ? "" : "sub-menu"}`}>
                      {menu.children.map((child: TMenuItem, index: number) => {
                        return (
                          <li key={index}>
                            <Link href={`/${child.slug}`}>
                              {child.menu_item_title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })
          : null}
      </ul>
    );
  };

  const avaryaLogo = useMemo(() => {
    return (
      <Image
        width={120}
        height={60}
        src={
          "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo-header.webp"
        }
        alt="logo"
        loading="eager"
      />
    );
  }, []);

  const renderUserIcon = useMemo(() => {
    return (
      <div className="header-action-icon-2 nav-link-text">
        <Link
          className="d-flex"
          href={
            Object.keys(logged_in_user || {}).length > 0
              ? "#"
              : logged_in_user === null
              ? API_ROUTES.LOGIN
              : "#"
          }
        >
          {Object.keys(logged_in_user || {}).length > 0 ? (
            <React.Fragment>
              <span className="nav-item-text d-none d-sm-block">
                {`Hello, ${logged_in_user?.name?.split(" ")?.[0]}`}
                {logged_in_user && <i className="fi-rs-angle-down"></i>}
              </span>
              <Image
                width={20}
                height={20}
                className="svgInject d-block d-sm-none"
                alt="user"
                src={user}
              />
            </React.Fragment>
          ) : (
            <Image
              width={20}
              height={20}
              className="svgInject"
              alt="user"
              src={user}
            />
          )}
        </Link>
        {logged_in_user?.name && (
          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
            <ul>
              <li>
                <Link href="/account/profile">
                  <i className="fi fi-rs-settings-sliders mr-10"></i>
                  Account
                </Link>
              </li>
              <li>
                <Link
                  href="/"
                  onClick={() => {
                    dispatch(logoutUser());
                    router.refresh();
                  }}
                >
                  <i className="fi fi-rs-sign-out mr-10"></i>
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }, [Object.keys(logged_in_user || {}).length]);

  const renderCartIcon = useMemo(() => {
    return (
      <div className="header-action-icon-2">
        <a
          className="mini-cart-icon"
          onClick={() => {
            if (
              pathNames?.filter((path) => cartRedirect?.includes(path))
                ?.length > 0 ||
              cartItems?.length === 0
            ) {
              router.push("/cart");
            } else {
              cartItems.length > 0 &&
                dispatch(setOffCanvas({ state: true, component: "cart" }));
            }
          }}
        >
          <Image width={20} height={20} alt="cart-icon" src={cart} />
          {cartItemsCounter > 0 && (
            <span className="pro-count blue">{cartItemsCounter}</span>
          )}
        </a>
      </div>
    );
  }, [cartItemsCounter, cartItems.length, paths]);

  const renderWishlistIcon = useMemo(() => {
    return (
      Object.keys(logged_in_user || {}).length > 0 && (
        <div className="header-action-icon-2">
          <Link href="/wishlist">
            <Image
              width={20}
              height={20}
              className="svgInject"
              alt="wishlist"
              src={heart}
            />
            {wishlistCounter > 0 && (
              <span className="pro-count blue">{wishlistCounter}</span>
            )}
          </Link>
        </div>
      )
    );
  }, [Object.keys(logged_in_user || {}).length, wishlistCounter]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollCheck = window.scrollY >= 150;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  useEffect(() => {
    // Handle token validation and user fetch
    const handleTokens = () => {
      if (
        tokens.refresh_token &&
        !isTokenValid(jwtDecode(tokens.refresh_token))
      ) {
        dispatch(logoutUser());
        // router.push(API_ROUTES.LOGIN);
      } else if (
        tokens.access_token &&
        isTokenValid(jwtDecode(tokens.access_token))
      ) {
        dispatch(fetchUserDetails());
      }
    };

    handleTokens();

    const megaMenu = document.getElementById("mega-menu");
    const megaMenuLinks = document.querySelectorAll("#mega-menu .mega-menu a");

    if (!megaMenu) return; // If megaMenu is null, exit early

    const megaMenuContent = megaMenu.querySelector(".mega-menu") as HTMLElement;

    const handleClick = () => {
      if (megaMenuContent) {
        megaMenuContent.style.display = "none"; // Hide mega menu when a link is clicked
      }
    };

    const handleMouseEvents = () => {
      if (megaMenuContent) {
        megaMenuContent.style.display = "block"; // Show mega menu when hovered again
      }
    };

    // Add event listeners to mega menu links for the click event
    megaMenuLinks.forEach((link) => {
      link.addEventListener("click", handleClick);
    });

    // Add event listeners to mega menu for mouse enter and leave
    megaMenu.addEventListener("mouseenter", handleMouseEvents);
    megaMenu.addEventListener("mouseleave", handleMouseEvents);
    // Cleanup function to remove event listeners when component unmounts
    return () => {
      megaMenuLinks.forEach((link) => {
        link.removeEventListener("click", handleClick);
      });
      megaMenu.removeEventListener("mouseenter", handleMouseEvents);
      megaMenu.removeEventListener("mouseleave", handleMouseEvents);
    };
  }, [Object.values(tokens).length]);

  return (
    <header
      className={`header-area header-style-1 header-height-2 sticky-bar ${
        scroll ? "stick" : ""
      } ${transparent ? "header-transparent sticky-bar stick" : ""}`}
    >
      <div
        className={`header-middle header-middle-ptb-1 d-none d-lg-${
          transparent ? (scroll ? "none" : "block") : "block"
        }`}
      >
        <div className="container">
          <div className="header-wrap">
            <div className="logo logo-width-1">
              <Link href="/">{avaryaLogo}</Link>
            </div>
            <div className="header-right">
              <div className="search-style-2 d-flex flex-row align-items-center gap-2">
                <Search instanceID="product-search" />
                <div className="footer-social">
                  <div className="mobile-social-icon d-flex flex-row align-items-center">
                    {/* <h6>Follow Us</h6> */}
                    <Link
                      href="https://www.facebook.com/avaryaretail"
                      target="_blank"
                    >
                      <Image
                        width={50}
                        height={50}
                        className="img-fluid h-100 w-100"
                        src={facebook}
                        alt="Avarya"
                      />
                    </Link>
                    <Link
                      href="https://www.instagram.com/avaryaretail"
                      target="_blank"
                    >
                      <Image
                        width={50}
                        height={50}
                        className="img-fluid h-100 w-100"
                        src={instagram}
                        alt="Avarya"
                      />
                    </Link>
                    <Link
                      href="https://www.youtube.com/@avaryaretail"
                      target="_blank"
                    >
                      <Image
                        width={50}
                        height={50}
                        className="img-fluid h-100 w-100"
                        src={youtube}
                        alt="Avarya"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="header-action-right">
                <div className="header-action-2">
                  {renderUserIcon}
                  {renderWishlistIcon}
                  {renderCartIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`header-bottom header-bottom-bg-color ${
          scroll ? "d-none d-lg-block d-xl-block" : ""
        }
            ${
              transparent
                ? `text-black ${scroll ? "bg-primary-2 text-black" : ""}`
                : ""
            }`}
      >
        <div className="container">
          <div className="header-wrap justify-content-center position-relative">
            <div className="d-flex align-items-center">
              <div className="header-action-icon-2 d-block d-lg-none">
                <div
                  className="burger-icon burger-icon-white"
                  onClick={toggleClick}
                >
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>
              <div className="logo logo-width-1 d-block d-lg-none">
                <Link href="/">{avaryaLogo}</Link>
              </div>
            </div>
            <div className="header-nav d-none d-lg-flex">
              <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading">
                <nav>{renderHeader({ data: header, is_mega_menu: false })}</nav>
              </div>
            </div>
            <div className="header-action-right d-flex d-lg-none">
              <div className="header-action-2">
                {renderUserIcon}
                {renderWishlistIcon}
                {renderCartIcon}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile header bottom search bar */}
      <div
        className={`header-bottom header-bottom-mobile-search-bar header-bottom-bg-color ${
          transparent
            ? `text-black ${scroll ? "bg-primary-2 text-black" : ""}`
            : ""
        }`}
      >
        <div className="container d-flex justify-content-center">
          <div className="search-style-2 d-flex justify-content-center mb-5 mt-5">
            <Search instanceID="mobile-bottom-header" />
          </div>
        </div>
      </div>
    </header>
  );
};

export { Header };
