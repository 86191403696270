"use client";

import parse from "html-react-parser";
import Image from "next/image";
import { BannerItemProps } from "@/utils";
import { Button } from "@/components/core-components";
import { useRouter } from "next/navigation";

const GiftHamper = ({
  data = {
    subTitle: "The Sweet Truth",
    title: "Gift hamper <br /> for your loved ones!",
    description:
      "Make this Diwali memorable for your special ones and spread love with our amazing and top quality delicacies",
    img: "/assets/imgs/banner/festive-banner-5.png",
    price: 699,
    btnText: "Only at ₹699",
  },
  widgetIndex,
}: {
  data?: BannerItemProps;
  widgetIndex: number;
}) => {
  const router = useRouter();
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="row mb-x">
            <div className="col-lg-4 text-left">
              {data.subTitle && (
                <p className="mb-15 text-primary font-xl montserrat-600 title-1">
                  {data.subTitle}
                </p>
              )}
              <h2 className="mb-15 prata-regular text-extra-dark">
                {parse(data.title)}
              </h2>
              <p className="mb-30 font-lg line-height-2">
                {parse(data.description || "")}
              </p>
              <Button
                className="btn btn-lg bg-green font-xl text-white"
                text={data.btnText}
                type={"button"}
                loading={false}
                onClick={() => {
                  if (data.link) {
                    router.push(data.link);
                  }
                }}
              />
            </div>
            <div className="col-lg-8">
              <Image
                width={800}
                height={800}
                alt={data.subTitle || ""}
                src={data.img}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftHamper;
