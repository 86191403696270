"use client";

import React, { useEffect, useState } from "react";
import {
  BUTTON_CONSTANTS,
  displayValue,
  formatDate,
  GENERAL_CONSTANTS,
  generateRazorpayOptions,
  IOrderAddress,
  IOrderDetail,
  STATIC_CONSTANTS,
  STATUSES,
  to2Decimal,
} from "@/utils";
import { Button, Loader, Ratings } from "@/components/core-components";
import Link from "next/link";

import {
  addToCart,
  incrementCartItemsCounter,
  printOrderInvoice,
  setWholeCart,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import Script from "next/script";
import { useRouter } from "next/navigation";
import { OrderInfo } from "./OrderInfo";
import Image from "next/image";

const OrderDetail = ({ orderDetails }: { orderDetails: IOrderDetail }) => {
  const dispatch = useAppDispatch();

  const { status } = useAppSelector((state) => state.order);

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { cartItems } = useAppSelector((state) => state.cart);

  const is_logged_in = logged_in_user || access_token;

  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // const payment_mode = useRef(null);

  const shippingAddress = orderDetails.addresses.find(
    (address: IOrderAddress) => address.address_type === "shipping"
  );

  const billingAddress = orderDetails.addresses.find(
    (address: IOrderAddress) => address.address_type === "billing"
  );

  const router = useRouter();

  const successfullPayment = orderDetails?.payment_data?.find(
    (item) =>
      item.payment_status === "success" &&
      item.payment_amount === orderDetails?.payable_amount
  );

  const canRetryPayment =
    orderDetails?.order_status === GENERAL_CONSTANTS.PENDING &&
    orderDetails?.payment_status === GENERAL_CONSTANTS.PENDING;
  // (orderDetails?.payment_data?.every(
  //   (item) => item.payment_status === "failed"
  // ) ||
  //   orderDetails?.payment_data?.length === 0 ||
  //   orderDetails?.payment_data?.some(
  //     (item) => item.payment_gateway_id === null
  //   )) &&
  // !orderDetails?.payment_data?.some(
  //   (item) =>
  //     item.payment_status === "success" &&
  //     item.payment_amount === orderDetails?.payable_amount
  // ) &&
  // isBefore(
  //   orderDetails?.payment_data?.[orderDetails?.payment_data?.length - 1]
  //     ?.created_at || new Date(),
  //   2
  // );

  const retryPayment = async () => {
    const failedPayment = orderDetails?.payment_data?.[0];

    // let paymentMappingToUse = failedPayment.id;

    // if (
    //   orderDetails?.payment_data?.length === 0 ||
    //   !orderDetails?.payment_data?.[0]?.payment_gateway_id
    // ) {
    //   const newPaymentMapping = await addPaymentMapping({
    //     razorpay_order_id: failedPayment?.payment_gateway_order,
    //     order_id: orderDetails.id,
    //   });

    //   paymentMappingToUse = newPaymentMapping;
    // }

    const options = generateRazorpayOptions(
      {
        amount: orderDetails?.payable_amount,
        id: failedPayment?.payment_gateway_order,
        notes: {
          customer_contact_number: orderDetails?.customer?.contact_number,
          customer_email: orderDetails?.customer?.email,
          customer_name: orderDetails?.customer?.name,
          order_id: failedPayment?.order,
        },
        // payment_mapping_id: paymentMappingToUse || 0,
      },
      paymentFailure,
      router,
      dispatch,
      true,
      setLoading
    );

    const paymentObject = new window.Razorpay(options);

    // paymentObject.on("payment.submit", async function (response: any) {
    //   payment_mode.current = response.method;
    // });

    // paymentObject.on("payment.failed", async function (response: any) {
    //   const orderDetail = await fetch(
    //     `/api/razorpay/order/${response.error.metadata.order_id}/`
    //   ).then((res) => res?.json());

    //   await onPaymentFailure({
    //     paymentFailure: orderDetail.attempts > 1,
    //     error_response: response,
    //     razorpay_order_response: {
    //       notes: {
    //         order_id: orderDetails.id,
    //       },
    //       payment_mapping_id: failedPayment?.id || 0,
    //       id: failedPayment?.payment_gateway_order || "",
    //     },
    //   });

    //   setPaymentFailure(true);
    // });

    paymentObject.open();
  };

  useEffect(() => {
    router.refresh();

    if (is_logged_in) {
      dispatch(setWholeCart({}));
    }
  }, []);

  return (
    <React.Fragment>
      <Script
        id="razorpay-checkout-js"
        src={STATIC_CONSTANTS.RAZORPAY_CONSTANTS.CHECKOUT_URL}
      />
      <section className="section-padding-12 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-2 mb-0">
                <h3>Order Details</h3>
              </div>
            </div>
            <div className="col-lg-12 d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-between align-items-end pb-15">
              <p className="mb-10">
                {`Ordered on ${formatDate(orderDetails?.created_at)}`}
                {orderDetails?.invoice_code && (
                  <React.Fragment>
                    <span className="font-xl pl-10 pr-10">|</span>
                    <span>{`Order ID ${orderDetails?.invoice_code}`}</span>
                  </React.Fragment>
                )}
              </p>
              <div className="d-flex flex-row gap-3">
                {canRetryPayment && (
                  <Button
                    text="Retry Payment"
                    type={BUTTON_CONSTANTS.BUTTON}
                    className="btn btn-xs"
                    loading={loading}
                    onClick={() => retryPayment()}
                  />
                )}
                {successfullPayment !== undefined && (
                  <Button
                    text="Download Invoice"
                    type={BUTTON_CONSTANTS.BUTTON}
                    className="btn btn-xs"
                    loading={status === STATUSES.LOADING}
                    onClick={() => {
                      dispatch(
                        printOrderInvoice({ order_id: orderDetails.id })
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {/* cart left section */}
            <div className="col-lg-12">
              <div className="table-responsive shopping-summery">
                {orderDetails?.ordered_products?.length <= 0 && "No Products"}
                <table
                  className={
                    orderDetails?.ordered_products?.length > 0
                      ? "table table-wishlist"
                      : "d-none"
                  }
                >
                  <thead>
                    <tr className="main-heading">
                      <th className="custome-checkbox start pl-30" colSpan={2}>
                        Product
                      </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Amount</th>
                      <th scope="col" className="end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails?.ordered_products?.map((item, i) => {
                      const isAddedToCart =
                        cartItems.find(
                          (cartItem) =>
                            cartItem.id === item.product.id &&
                            cartItem.weight_id === item.product_weight
                        ) !== undefined;

                      return (
                        <tr key={`order-cart-item-${i}`}>
                          <td className="image product-thumbnail" width={"12%"}>
                            <Image
                              width={180}
                              height={180}
                              className="img-fluid"
                              src={item.product.thumbnails?.[0]?.img}
                              alt={item.product.thumbnails?.[0]?.alt}
                            />
                          </td>

                          <td className="product-des product-name v-align-top">
                            <div>
                              <h6 className="product-name">
                                <Link href={`/${item.product.slug}`}>
                                  {item.product.title}
                                </Link>
                              </h6>
                              <div className="product-rate-cover">
                                <Ratings rating={item.product.rating} />
                              </div>
                            </div>
                          </td>

                          <td
                            className="price text-center v-align-top"
                            data-title=""
                          >
                            <h6 className="text-dark">{item.quantity}</h6>
                          </td>

                          <td
                            className="price text-center v-align-top"
                            data-title=""
                          >
                            <h6 className="text-dark">
                              <span className="rupee-symbol">₹</span>
                              {displayValue(
                                to2Decimal(item.total_amount / item.quantity)
                              )}
                            </h6>
                          </td>

                          <td
                            className="price text-center v-align-top"
                            data-title=""
                          >
                            <h6 className="text-dark">{`${item.product.selected_weight}${item.product.uom}`}</h6>
                          </td>

                          <td
                            className="price text-center v-align-top"
                            data-title=""
                          >
                            <h6 className="text-dark">
                              <span className="rupee-symbol">₹</span>
                              {displayValue(
                                to2Decimal(
                                  item.payable_amount + item.discount_value
                                )
                              )}
                            </h6>
                          </td>

                          <td className="text-center v-align-top">
                            {/* <Button
                            type={BUTTON_CONSTANTS.BUTTON}
                            className="btn btn-xs mr-20"
                            text="Write a product review"
                          /> */}
                            {isAddedToCart ? (
                              <p className="text-success">Added to cart</p>
                            ) : (
                              <Button
                                type={BUTTON_CONSTANTS.BUTTON}
                                className="btn btn-xs"
                                disabled={
                                  state.status === STATUSES.LOADING &&
                                  state.type ===
                                    `add-cartItem-${item.product.id}-${i}`
                                }
                                text={
                                  state.status === STATUSES.LOADING &&
                                  state.type ===
                                    `add-cartItem-${item.product.id}-${i}` ? (
                                    <Loader />
                                  ) : (
                                    "Buy Again"
                                  )
                                }
                                onClick={() => {
                                  dispatch(
                                    addToCart({
                                      product_id: item.product.id,
                                      quantity: 1,
                                      weight_id: item.product_weight,
                                      onSuccess: () => {
                                        dispatch(incrementCartItemsCounter());
                                      },
                                      index: i,
                                    })
                                  );
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <OrderTracking /> */}
      <section className="section-padding-12">
        <div className="container">
          <OrderInfo
            orderDetails={orderDetails}
            isOrderDetailPage={true}
            sectionclassName="col-lg-6 col-md-3"
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            cartItems={orderDetails?.ordered_products}
            successfullPayment={successfullPayment}
          />
        </div>
      </section>

      {/* <ListingWithFilters
        key={`best-deals`}
        hasDesc={false}
        type={"order-detail"}
        fetchUrl={`/shop/product/search/`}
        serverFilters={false}
        title="Best Deals"
        filters={["All", "Featured", "Popular", "New Added"]}
        extraFilters={false}
        largeCard={false}
        last={false}
      />

      <ProductHorizontalListing
        lengthToRender={1}
        key={"you-may-also-like"}
        hasDesc={false}
        slidesPerView={5.7}
        spaceBetween={30}
        prevBtn={"custom_prev_2"}
        nextBtn={"custom_next_2"}
        parentClassName="section-padding-12 "
        btnClassName="slider-arrow-2 carausel-6-columns-arrow"
        sliderClassName="col-12"
        sliderId="row related-products position-relative"
        data={carouselData?.["you-may-also-like"]?.data || []}
        onNavigationNext={fetchYouMayAlsoLike}
        type="you-may-also-like"
        title="You May Also Like"
        last={true}
      /> */}
    </React.Fragment>
  );
};

export { OrderDetail };
