"use client";

import { Loader, Ratings } from "@/components/core-components";
import {
  removeCartItem,
  removeProduct,
  setCartItemsCounter,
  updateCartItem,
  updateQuantity,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  displayValue,
  ICartItem,
  STATUSES,
  to2Decimal,
  trackAddToCartWishlist,
} from "@/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const CartItem = ({
  cartItem,
  index,
}: {
  cartItem: ICartItem;
  index: number;
}) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { cartItemsCounter, appliedDiscount } = useAppSelector(
    (state) => state.cart
  );

  const is_logged_in = logged_in_user || access_token;

  function trackRemoveCartItem() {
    const eventdata = {
      currency: "INR",
      value: to2Decimal(cartItem.payable_amount),
      items: [
        {
          item_id: cartItem.product_code,
          item_list_id: "cart",
          item_name: cartItem.title,
          item_brand: "Avarya",
          item_category: cartItem.category,
          item_category2: cartItem.sub_category,
          quantity: cartItem.quantity,
          price: cartItem.price,
        },
      ],
    };

    trackAddToCartWishlist("remove_from_cart", eventdata);
  }

  return (
    <tr>
      <td className="image product-thumbnail">
        <Image
          height={120}
          width={120}
          alt={cartItem.title}
          src={cartItem?.thumbnails?.[0]?.img}
          className="img-fluid"
        />
      </td>

      <td className="product-des product-name v-align-top">
        <div className="d-flex flex-column">
          <h6 className="product-name">
            <Link href={cartItem.slug}>{cartItem.title}</Link>
          </h6>
          <div className="product-rate-cover pb-1">
            <Ratings rating={cartItem.rating} />
          </div>
          <span className="text-danger underline">
            <Link
              href={"#"}
              onClick={() => {
                dispatch(
                  setCartItemsCounter(cartItemsCounter - cartItem.quantity)
                );
                if (is_logged_in) {
                  dispatch(removeCartItem({ cart_id: cartItem.cart_id }));
                  trackRemoveCartItem();
                } else {
                  dispatch(removeProduct({ index: index }));
                }
              }}
            >
              {is_logged_in &&
              state.status === STATUSES.LOADING &&
              state.type === `delete-cartItem-${cartItem.cart_id}` ? (
                <Loader />
              ) : (
                "Delete"
              )}
            </Link>
          </span>
        </div>
      </td>

      <td className="text-center v-align-top detail-info pl-10 pr-10">
        <h6 className="text-brand">{`${cartItem.weight}${cartItem.uom}`}</h6>
      </td>

      <td className="price text-center v-align-top">
        <h6 className="text-brand">
          <span className="rupee-symbol">₹</span>
          {displayValue(cartItem.price)}
        </h6>
      </td>

      <td className="text-center detail-info v-align-top">
        <div className="d-flex flex-row justify-content-center detail-extralink">
          <div className="quantity-selector">
            <span className="qty-val">{cartItem.quantity}</span>
            <div className="quantity-carets">
              <a
                onClick={() => {
                  if (is_logged_in) {
                    dispatch(
                      updateCartItem({
                        cart_id: cartItem.cart_id,
                        quantity: cartItem.quantity + 1,
                      })
                    );
                  } else {
                    dispatch(
                      updateQuantity({
                        index: index,
                        quantity: cartItem.quantity + 1,
                      })
                    );
                  }
                }}
                className="qty-up"
              >
                <i className="fi-rs-angle-small-up"></i>
              </a>
              <a
                className="qty-down"
                onClick={() => {
                  if (is_logged_in) {
                    dispatch(
                      updateCartItem({
                        cart_id: cartItem.cart_id,
                        quantity: cartItem.quantity - 1,
                      })
                    );
                  } else {
                    dispatch(
                      updateQuantity({
                        index: index,
                        quantity: cartItem.quantity - 1,
                      })
                    );
                  }
                }}
              >
                <i className="fi-rs-angle-small-down"></i>
              </a>
            </div>
          </div>
        </div>
      </td>

      {appliedDiscount.discount_value > 0 ? (
        <td className="price text-center v-align-top">
          <h6 className="text-brand">
            <span className="rupee-symbol">₹</span>
            {displayValue(cartItem.discount_value)}
          </h6>
        </td>
      ) : null}

      <td className="text-center v-align-top">
        <h6 className="fw-600 font-18">
          <span className="rupee-symbol">₹</span>
          {displayValue(cartItem.payable_amount)}
        </h6>
      </td>
    </tr>
  );
};

export { CartItem };
