"use client";

import Link from "next/link";
import React, { useCallback, useMemo } from "react";
import { ICartItem, STATUSES, to2Decimal } from "@/utils";
import { CustomSelect, Loader, Ratings } from "@/components/core-components";
import {
  decrementCartItemsCounter,
  incrementCartItemsCounter,
  removeCartItem,
  removeProduct,
  setCartItemsCounter,
  setOffCanvas,
  updateCartItem,
  updateQuantity,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import Image from "next/image";

const OrderItem = ({
  showQuantity,
  i,
  cartItem,
  cartItemsLength,
}: {
  showQuantity: boolean;
  i: number;
  cartItem: ICartItem;
  cartItemsLength: number;
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { cartItemsCounter } = useAppSelector((state) => state.cart);

  const is_logged_in = logged_in_user || access_token;

  const payableAmount = useMemo(
    () => to2Decimal(cartItem?.price * cartItem.quantity),
    [cartItem.quantity]
  );

  return (
    <article
      className={`row align-items-center hover-up article-card ${
        showQuantity ? "" : "bg-primary-2 border-none"
      }`}
      key={i}
    >
      <figure className="col-md-4 col-4 mb-0">
        <Link href="/[slug]" as={`/${cartItem.slug}`}>
          <Image
            src={cartItem.thumbnails?.[0]?.img}
            alt={cartItem.title}
            height={165}
            width={165}
          />
        </Link>
      </figure>

      <div className="col-md-8 col-8 mb-0 article-card-content">
        <h6>
          <Link href="/[slug]" as={`/${cartItem.slug}`}>
            {cartItem.title}
          </Link>
        </h6>

        <div className="d-flex flex-row justify-content-between align-items-center">
          <CustomSelect
            id={`order-item-${cartItem.title.replace(/\s/g, "-")}-weight`}
            onlyLabel={true}
            options={[
              {
                value: cartItem.weight,
                label: `${cartItem.weight}${cartItem.uom}`,
              },
            ]}
            name="weight"
            onChange={(e: any) => {}}
          />

          <div className="product-rate-cover">
            <Ratings rating={cartItem.rating} />
          </div>
        </div>

        <div className="product-price d-flex flex-row align-items-center mb-10">
          <span className="d-flex flex-row align-items-start">
            <span className="rupee-symbol">₹</span>
            {payableAmount}
          </span>
          <span className="old-price">
            {cartItem.discount_value > 0 ? (
              <span className="old-price mr-10">
                <span className="rupee-symbol">₹</span>
                {payableAmount + cartItem.discount_value}
              </span>
            ) : null}
          </span>
        </div>

        <div className="d-flex gap-1 justify-content-between align-items-end">
          <div className="d-flex flex-row">
            <div className="detail-extralink">
              <div className="detail-qty">
                <a
                  className="qty-left"
                  onClick={() => {
                    if (is_logged_in) {
                      dispatch(
                        updateCartItem({
                          cart_id: cartItem.cart_id,
                          quantity: Number(cartItem.quantity - 1),
                        })
                      );
                    } else {
                      dispatch(
                        updateQuantity({
                          index: i,
                          quantity: Number(cartItem.quantity - 1),
                        })
                      );
                    }
                    dispatch(decrementCartItemsCounter());
                  }}
                >
                  <i className="fi-rs-angle-small-left"></i>
                </a>
                <span className="qty-val">{cartItem.quantity}</span>
                <a
                  className="qty-right"
                  onClick={() => {
                    if (is_logged_in) {
                      dispatch(
                        updateCartItem({
                          cart_id: cartItem.cart_id,
                          quantity: Number(cartItem.quantity + 1),
                        })
                      );
                    } else {
                      dispatch(
                        updateQuantity({
                          index: i,
                          quantity: Number(cartItem.quantity + 1),
                        })
                      );
                    }
                    dispatch(incrementCartItemsCounter());
                  }}
                >
                  <i className="fi-rs-angle-small-right"></i>
                </a>
              </div>
            </div>
          </div>

          <span className="text-danger font-18">
            <a
              className={`${
                is_logged_in &&
                state.status === STATUSES.LOADING &&
                state.type === `delete-cartItem-${cartItem.cart_id}`
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                dispatch(
                  setCartItemsCounter(cartItemsCounter - cartItem.quantity)
                );
                if (is_logged_in) {
                  dispatch(removeCartItem({ cart_id: cartItem.cart_id }));
                } else {
                  dispatch(removeProduct({ index: i }));
                }
                if (cartItemsLength === 1) {
                  dispatch(setOffCanvas({ state: false, component: "cart" }));
                }
              }}
            >
              {is_logged_in &&
              state.status === STATUSES.LOADING &&
              state.type === `delete-cartItem-${cartItem.cart_id}` ? (
                <Loader />
              ) : (
                <i className="fi-rs-trash"></i>
              )}
            </a>
          </span>
        </div>
      </div>
    </article>
  );
};

const OrderItems = ({
  showQuantity,
  cartItems,
}: {
  showQuantity: boolean;
  cartItems: any;
}) => {
  const { state } = useAppSelector((state) => state.common);

  const SingleItem = useCallback((cartItem: ICartItem, i: number) => {
    return (
      <OrderItem
        cartItem={cartItem}
        i={i}
        cartItemsLength={cartItems?.length}
        showQuantity={showQuantity}
      />
    );
  }, []);

  return state.status === STATUSES.LOADING && state.type === "fetch-cart" ? (
    <div className="d-flex flex-row justify-content-center align-items-center h-100 text-primary">
      <Loader size="lg" />
    </div>
  ) : cartItems?.length > 0 ? (
    cartItems?.map(SingleItem)
  ) : null;
};

export { OrderItems };
